import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as ArchiveIcon } from 'assets/header/archive.svg';
import { ReactComponent as UploadingIcon } from 'assets/header/uploading.svg';
import { isMediumMobile } from 'config/env';

import './RouterTabs.scss';

const RouterTabs: React.FC = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const isArchivePath = matchPath('/archive/*', pathname);
    const isUploadingPath = matchPath('/uploading/*', pathname);

    return (
        <div className='router_tabs'>
            <div
                onClick={() => navigate('/uploading')}
                className={`tab ${isUploadingPath ? 'tab_primary' : 'tab_secondary'}`}
            >
                {isMediumMobile ? (
                    <div className='tooltip'>
                        <UploadingIcon className='tab__icon' />
                        <span className='tooltiptext'>Загрузка аудио</span>
                    </div>
                ) : (
                    'Загрузка аудио'
                )}
            </div>
            <div
                onClick={() => navigate('/archive')}
                className={`tab ${isArchivePath ? 'tab_primary' : 'tab_secondary'}`}
            >
                {isMediumMobile ? (
                    <div className='tooltip'>
                        <ArchiveIcon className='tab__icon' />
                        <span className='tooltiptext'>Архив</span>
                    </div>
                ) : (
                    'Мои записи'
                )}
            </div>
        </div>
    );
};

export default RouterTabs;
