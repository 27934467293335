import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { appApi } from 'api/routes/AppApi';
import { ErrorMessage } from 'components/generic/errorMessage/ErrorMessage';
import { ValidatedInput, ValidatedTextarea } from 'components/generic/inputFields/InputFields';
import { FeedbackSchema } from 'components/generic/inputFields/InputFields.interfaces';
import { emailPattern } from 'helpers/ValidationPatterns';

import './ContactUs.scss';

const ContactUs: React.FC = () => {
    const methods = useForm<FeedbackSchema>({
        mode: 'onChange',
        defaultValues: {
            message: '',
            email: '',
        },
    });

    const [postFeedback, { isSuccess, error }] = appApi.usePostFeedbackMutation();

    useEffect(() => {
        if (isSuccess) {
            toast.success('Ваше сообщение отправлено');
            methods.reset();
        }
    }, [isSuccess, methods]);

    return (
        <div id='contact-us' className='landing-section'>
            <div className='content'>
                <p className='title'>Если у вас остались вопросы, свяжитесь с нами</p>
                <FormProvider {...methods}>
                    <form
                        onSubmit={methods.handleSubmit(async (data) => await postFeedback(data).unwrap())}
                        style={{ width: '100%' }}
                    >
                        {error && 'status' in error && error.status === 401 && (
                            <ErrorMessage message='Войдите в систему' />
                        )}
                        <div className='input_wrapper' style={{ marginBottom: '10px' }}>
                            <ValidatedInput required field='email' pattern={emailPattern} placeholder='Email' />
                            {methods.formState.errors.email && (
                                <ErrorMessage message={methods.formState.errors.email.message} />
                            )}
                        </div>
                        <div className='input_wrapper' style={{ marginBottom: '10px' }}>
                            <ValidatedTextarea required field='message' className='textarea' placeholder='Ваш вопрос' />
                            {methods.formState.errors.message && (
                                <ErrorMessage message={methods.formState.errors.message.message} />
                            )}
                        </div>

                        <div className='footer'>
                            <button type='submit' className='btn btn_blue' disabled={!methods.formState.isValid}>
                                Отправить
                            </button>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </div>
    );
};

export default ContactUs;
