import classNames from 'classnames';
import { add, getMonth, getYear, isSameDay, sub } from 'date-fns';
import { useCallback, useMemo, useRef, useState } from 'react';

import { ReactComponent as Arrow } from 'assets/arrow-without-body.svg';
import { CalendarNavigationProps } from 'components/views/searchFilter/calendar/Calendar.interfaces';
import { calendarModes, MONTHS } from 'helpers/Constants';
import useOutsideClick from 'hooks/useOutsideClick';

import './CalendarNavigation.scss';

const CalendarNavigation: React.FC<CalendarNavigationProps> = (props): JSX.Element => {
    const currentDate = new Date();
    const selectedDate = props.selectedDate;
    const monthDropdownRef = useRef<HTMLUListElement>(null);
    const yearDropdownRef = useRef<HTMLUListElement>(null);

    const [monthsListIsOpen, setMonthsListIsOpen] = useState(false);
    const [yearsListIsOpen, setYearsListIsOpen] = useState(false);

    const handleNextMonth = useCallback(() => {
        const newDate = add(props.selectedDate, { months: 1 });
        props.setSelectedDate(newDate);
    }, [props]);

    const handlePreviousMonth = useCallback(() => {
        const newDate = sub(props.selectedDate, { months: 1 });
        props.setSelectedDate(newDate);
    }, [props]);

    const handleMode = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            if (props.currentMode !== event.currentTarget.value) {
                props.changeCurrentMode(event.currentTarget.value);
                props.changeSelectedPeriod([]);
                props.setSearchDate(null);
            }
        },
        [props],
    );

    const handleSelectMonth = useCallback(
        (month: number): void => {
            const r = month - getMonth(selectedDate);
            props.setSelectedDate(add(selectedDate, { months: r }));

            setMonthsListIsOpen(false);
        },
        [props, selectedDate],
    );

    const handleSelectYear = useCallback(
        (year: number): void => {
            const r = year - getYear(selectedDate);
            props.setSelectedDate(add(selectedDate, { years: r }));

            setYearsListIsOpen(false);
        },
        [props, selectedDate],
    );

    const monthsList = useMemo(() => {
        let list: JSX.Element[] = [];
        for (let i = 0; i < 12; i++) {
            list.push(
                <li
                    key={MONTHS[i]}
                    className={`dropdown_list_item ${getMonth(selectedDate) === i ? 'selected_item' : ''}`}
                    onClick={() => handleSelectMonth(i)}
                >
                    {MONTHS[i]}
                </li>,
            );
        }
        return list;
    }, [handleSelectMonth, selectedDate]);

    const yearsList = useMemo(() => {
        let list: JSX.Element[] = [];
        const currentYear = getYear(new Date());
        const oldestYear: number = currentYear - 40;
        for (let i = currentYear; i >= oldestYear; i--) {
            list.push(
                <li
                    key={i}
                    className={`dropdown_list_item ${getYear(selectedDate) === i ? 'selected_item' : ''}`}
                    onClick={() => handleSelectYear(i)}
                >
                    {i}
                </li>,
            );
        }
        return list;
    }, [handleSelectYear, selectedDate]);

    useOutsideClick([monthDropdownRef], () => {
        setMonthsListIsOpen(false);
    });

    useOutsideClick([yearDropdownRef], () => {
        setYearsListIsOpen(false);
    });

    return (
        <div className='calendar_navigation'>
            <div className='line_container'>
                <div className='choose_mode'>
                    <button
                        value={calendarModes.date}
                        onClick={handleMode}
                        className={classNames('field', { 'field--selected': props.currentMode === calendarModes.date })}
                    >
                        Дата
                    </button>
                    <button
                        value={calendarModes.period}
                        onClick={handleMode}
                        className={classNames('field', {
                            'field--selected': props.currentMode === calendarModes.period,
                        })}
                    >
                        Период
                    </button>
                </div>
                <button
                    className={classNames('field', {
                        disabled_button: props.currentMode === calendarModes.period,
                        'field--selected':
                            !(props.currentMode === calendarModes.period) && isSameDay(selectedDate, currentDate),
                    })}
                    onClick={() => props.changeSelectedDate(new Date())}
                >
                    Сегодня
                </button>
            </div>
            <div className='line_container'>
                <div className='month_navigation'>
                    <button className='navigation_button' onClick={handlePreviousMonth}>
                        <Arrow className='arrow--left' />
                    </button>
                    <div className='field_container current_month'>
                        <button className='field ' onClick={() => setMonthsListIsOpen(!monthsListIsOpen)}>
                            {MONTHS[getMonth(props.selectedDate)]}
                        </button>
                        {monthsListIsOpen && (
                            <ul className='dropdown_list' ref={monthDropdownRef}>
                                {monthsList}
                            </ul>
                        )}
                    </div>
                    <button className='navigation_button' onClick={handleNextMonth}>
                        <Arrow className='arrow--right' />
                    </button>
                </div>
                <div className='field_container'>
                    <button className='field' onClick={() => setYearsListIsOpen(!yearsListIsOpen)}>
                        {getYear(props.selectedDate)}
                    </button>
                    {yearsListIsOpen && (
                        <ul className='dropdown_list' ref={yearDropdownRef}>
                            {yearsList}
                        </ul>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CalendarNavigation;
