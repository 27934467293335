import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useChangePasswordMutation } from 'api/routes/AppApi';
import CrossButton from 'components/generic/crossButton/CrossButton';
import { ErrorMessage } from 'components/generic/errorMessage/ErrorMessage';
import { ValidatedInput } from 'components/generic/inputFields/InputFields';
import { inputTypes } from 'components/generic/inputFields/InputFields.interfaces';

import './ProfilePassword.scss';

const ProfilePassword: React.FC = () => {
    const [changePassword, { isError }] = useChangePasswordMutation();
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const methods = useForm({
        mode: 'onChange',
        defaultValues: {
            old_password: '',
            new_password: '',
            repeated_password: '',
        },
    });

    return (
        <React.Fragment>
            <div className='profile_password_section'>
                <CrossButton handleClick={() => navigate('/profile/info')} />
                <p className='section_title'>Изменение пароля</p>
                <FormProvider {...methods}>
                    <form
                        className='profile_password_form'
                        onSubmit={methods.handleSubmit((data) => {
                            changePassword(data)
                                .unwrap()
                                .then(() => {
                                    toast.success('Пароль успешно обновлен');
                                    navigate('/profile/info');
                                })
                                .catch(() => {
                                    setErrorMessage('Не удалось изменить пароль');
                                });
                        })}
                    >
                        <div className='error_loader_container'>
                            {isError && <ErrorMessage message={errorMessage} />}
                        </div>

                        <div className='input_wrapper'>
                            <ValidatedInput
                                required
                                field='old_password'
                                placeholder='Старый пароль'
                                abilityToHide
                                type={inputTypes.password}
                                autocompleteOff
                            />
                            {methods.formState.errors.old_password && (
                                <ErrorMessage message={methods.formState.errors.old_password.message} />
                            )}
                        </div>
                        <div className='input_wrapper'>
                            <ValidatedInput
                                required
                                field='new_password'
                                placeholder='Новый пароль'
                                abilityToHide
                                type={inputTypes.password}
                                autocompleteOff
                            />
                            {methods.formState.errors.new_password && (
                                <ErrorMessage message={methods.formState.errors.new_password.message} />
                            )}
                        </div>
                        <div className='input_wrapper'>
                            <ValidatedInput
                                required
                                field='repeated_password'
                                placeholder='Повторите пароль'
                                abilityToHide
                                type={inputTypes.password}
                                validateValue={methods.watch('new_password')}
                                autocompleteOff
                            />
                            {methods.formState.errors.repeated_password && (
                                <ErrorMessage message={methods.formState.errors.repeated_password.message} />
                            )}
                        </div>
                        <button type='submit' className='btn btn_primary' disabled={!methods.formState.isValid}>
                            Сохранить
                        </button>
                    </form>
                </FormProvider>
            </div>
        </React.Fragment>
    );
};

export default ProfilePassword;
