export const lettersPattern = /^[a-zA-Zа-яА-ЯЁё_s-]+$/;

export const numbersPattern = /^[0-9]+$/;

export const emailPattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const emailPatternString = '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';

export const tinPattern = /^(([0-9]{12})|([0-9]{10}))?$/;
