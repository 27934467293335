import { getRefreshToken } from 'helpers/LocalStorageActions';

const parseJwt = (token: string) => {
    try {
        return JSON.parse(window.atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};

export const checkIfTokenValid = (token: string) => {
    const decodedJwt = parseJwt(token);
    return decodedJwt ? decodedJwt.exp * 1000 > Date.now() : false;
};

export const checkIfAuthorized = () => {
    const savedToken = getRefreshToken();
    return savedToken ? checkIfTokenValid(savedToken) : false;
};
