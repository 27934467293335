import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import { RequestProps } from './RequestItem.interface';

import { useRevokeRequestMutation } from 'api/routes/ApiApi';
import { ModalWithDimming } from 'components/generic/modal/Modal';
import ProgressBar from 'components/generic/progressbar/ProgressBar';

const RequestItem: React.FC<RequestProps> = ({ id, name, isFailed, taskId, timestamp, duration }) => {
    const [revokeRequest] = useRevokeRequestMutation();

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [elapsedTime, setElapsedTime] = useState<number>(
        Math.abs(new Date().getTime() - new Date(timestamp).getTime()),
    );

    const renderModalBody = (setModalIsOpen: Dispatch<SetStateAction<boolean>>, id: string) => (
        <React.Fragment>
            <p className='text_light'>Вы действительно хотите прервать расшифровку?</p>
            <p className='text_light'>Отменить это действие будет невозможно.</p>
            <div className='modal_actions'>
                <button type='button' className='cancel_button btn btn_light' onClick={() => setModalIsOpen(false)}>
                    Отменить
                </button>
                <button
                    type='button'
                    className='confirm_button btn btn_danger'
                    onClick={() => {
                        setModalIsOpen(false);
                        handleRevokeTask();
                    }}
                >
                    Да, прервать
                </button>
            </div>
        </React.Fragment>
    );

    const handleRevokeTask = useCallback(() => {
        try {
            revokeRequest({ taskId: taskId });
        } catch (e) {
            console.log(e);
        }
    }, [revokeRequest, taskId]);

    useEffect(() => {
        const interval = setInterval(() => {
            setElapsedTime(Math.abs(new Date().getTime() - new Date(timestamp).getTime()));
        }, 10000);

        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='active_download_item'>
            {!isFailed ? (
                <div className='progress_wrapper'>
                    <div className='percent_actions_wrapper'>
                        <span>В процессе...</span>
                        <div className='cancel' onClick={() => setModalIsOpen(true)}>
                            Отменить
                        </div>
                    </div>
                    <ProgressBar progress={(100 * elapsedTime) / (duration * 3)} animated />
                </div>
            ) : (
                <div className='failed_wrapper'>
                    <span>Что-то пошло не так</span>
                    <div className='hide' onClick={handleRevokeTask}>
                        Скрыть
                    </div>
                </div>
            )}
            <div className='name'>{name}</div>
            {modalIsOpen && (
                <ModalWithDimming
                    hideModal={() => setModalIsOpen(false)}
                    modalBody={renderModalBody(setModalIsOpen, id)}
                    modalTitle='Отмена'
                />
            )}
        </div>
    );
};

export default RequestItem;
