import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CommentProps } from 'components/views/commentsList/commentItem/CommentItem.interfaces';
import { testComments } from 'helpers/TestData';

interface CommentState {
    comments: CommentProps[];
    isLoading: boolean;
    error: string;
    isCreated: boolean;
}

const initialState: CommentState = { comments: testComments, isLoading: false, error: '', isCreated: false };

export const commentsSlice = createSlice({
    name: 'comments',
    initialState,
    reducers: {
        addComment: (state, action: PayloadAction<CommentProps>) => {
            state.comments.push(action.payload);
        },
        setIsCreated: (state, action: PayloadAction<boolean>) => {
            state.isCreated = action.payload;
        },
    },
    extraReducers: {},
});

export const { addComment, setIsCreated } = commentsSlice.actions;
export default commentsSlice.reducer;
