import { useCallback, useEffect, useState } from 'react';

import RecordFileUploader from './recordFileUploader/RecordFileUploader';
import TranscriptInfoForm from './transcriptInfoForm/TranscriptInfoForm';
import { FileInfoPayload } from './UploadingPage.interface';
import './UploadingPage.scss';
import UploadingProgress from './uploadingProgress/UploadingProgress';

import { useDeleteFileMutation } from 'api/routes/RecordsApi';
import { ReactComponent as MicrophoneIcon } from 'assets/microphone.svg';
import Stepper from 'components/generic/stepper/Stepper';
import { LS } from 'config/env';

const UploadingPage: React.FC = (): JSX.Element => {
    const [step, setStep] = useState<number>(
        localStorage.getItem(LS.UPLOADING_STEP) ? +localStorage.getItem(LS.UPLOADING_STEP)! : 0,
    );
    const [uploadedFiles, setUploadedFiles] = useState<FileInfoPayload[]>(
        localStorage.getItem(LS.FILES) ? JSON.parse(localStorage.getItem(LS.FILES)!) : [],
    );

    const [deleteFile] = useDeleteFileMutation();

    useEffect(() => {
        localStorage.setItem(LS.UPLOADING_STEP, step === 2 ? '0' : step.toString());
    }, [step]);

    const navigateToFirstStep = useCallback(() => {
        setStep(0);
    }, []);

    const navigateToNextStep = useCallback(() => {
        setStep((prev) => prev + 1);
    }, []);

    const addFileToUploaded = useCallback((file: FileInfoPayload) => {
        setUploadedFiles((prev) => [...prev, file]);
    }, []);

    const clearAllUploadedFiles = useCallback(() => {
        setUploadedFiles([]);
        localStorage.removeItem(LS.FILES);
    }, []);

    const removeUploadedFileById = useCallback((id: string) => {
        setUploadedFiles((prev) => prev.filter((el) => el.id !== id));
    }, []);

    const handleCancelUpload = useCallback(() => {
        Promise.all(uploadedFiles.filter((el) => el.created).map((el) => deleteFile(el.id))).finally(() => {
            clearAllUploadedFiles();
            navigateToFirstStep();
        });
    }, [clearAllUploadedFiles, deleteFile, navigateToFirstStep, uploadedFiles]);

    return (
        <div className='record_file_uploader'>
            <h2 className='record_file_uploader__title'>Загрузка аудио</h2>
            <div className='record_file_uploader__content'>
                <div className='steps_wrapper'>
                    <Stepper step={step} stepsNames={['Загрузка файла', 'Детали', 'Расшифровка']} />
                    {step === 0 && (
                        <RecordFileUploader
                            {...{
                                navigateToNextStep,
                                uploadedFiles,
                                addFileToUploaded,
                                handleCancelUpload,
                                removeUploadedFileById,
                            }}
                        />
                    )}
                    {step === 1 && (
                        <TranscriptInfoForm
                            {...{ navigateToNextStep, handleCancelUpload, uploadedFiles, clearAllUploadedFiles }}
                        />
                    )}
                    {step === 2 && <UploadingProgress {...{ navigateToFirstStep }} />}
                </div>

                <div className='info_wrapper'>
                    <MicrophoneIcon className='info_icon' />
                    <div className='info_text'>
                        <p className='info_text__title'>90% качества расшифровки – качество аудио</p>
                        <p className='info_text__body'>
                            Внимание: несколько одновременно загруженных файлов будут расшифрованы с одинаковыми
                            параметрами расшифровки. Если нужно расшифровать записи по разному, загрузите, пожалуйста,
                            по отдельности.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UploadingPage;
