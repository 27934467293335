import aim from 'assets/greeting/aim.svg';
import rocket from 'assets/greeting/rocket.svg';

import './Greeting.scss';

const Greeting: React.FC = () => (
    <div id='greeting' className='landing-section landing-section_background'>
        <div className='content'>
            <div className='info'>
                <div className='info__title'>Система автоматического стенографирования</div>
                <div className='info__subtitle'>Вам не нужно больше конспектировать ваши разговоры</div>
            </div>
            <div className='features'>
                <div className='features__item'>
                    <img src={rocket} className='features__item-icon' alt='rocket' />
                    <div className='features__item-text'>Фиксируйте ваши встречи</div>
                </div>
                <div className='features__item'>
                    <img src={aim} className='features__item-icon' alt='aim' />
                    <div className='features__item-text'>Создавайте редактируемые расшифровки</div>
                </div>
            </div>
        </div>
    </div>
);

export default Greeting;
