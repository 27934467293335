import { useCallback, useRef } from 'react';

const useThrottle = (callback: (...args: any[]) => void, delay: number): ((...args: unknown[]) => void) => {
    const throttleInProgress = useRef<boolean>(false);

    return useCallback(
        (...args) => {
            if (throttleInProgress.current) {
                return;
            }
            throttleInProgress.current = true;
            setTimeout(() => {
                callback(...args);
                throttleInProgress.current = false;
            }, delay);
        },
        [callback, delay],
    );
};

export default useThrottle;
