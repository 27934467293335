import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import {
    CommentPayload,
    DictionariesPayload,
    DictionaryPayload,
    DictionaryWordPayload,
    EditResultPayload,
    NewDictionaryPayload,
    NewDictionaryWordPayload,
    RecognizeBodyPayload,
    RecognizePayload,
    RequestsPayload,
    ResultsPayload,
    SearchResultsPayload,
    SpeakerPayload,
    SpeakersPayload,
    TranscriptPayload,
    TranscriptSummariesPayload,
} from '../Models';

import { CommentProps } from 'components/views/commentsList/commentItem/CommentItem.interfaces';
import { getAPIToken } from 'helpers/LocalStorageActions';

const API_API_HOST = process.env.REACT_APP_API_API_HOST;

export const apiApi = createApi({
    reducerPath: 'apiApi',
    tagTypes: ['DictionariesList', 'Requests', 'Results', 'Result', 'Speakers', 'Dictionary'],
    baseQuery: fetchBaseQuery({
        baseUrl: `${API_API_HOST}/api/`,
        prepareHeaders: (headers) => {
            const token = getAPIToken();
            if (!headers.has('authorization') && token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),

    endpoints: (build) => {
        return {
            recognizeFile: build.mutation<RecognizePayload, RecognizeBodyPayload>({
                query: (fileInfo) => ({
                    url: `recognize/${fileInfo.fileId}/`,
                    method: 'POST',
                    body: fileInfo,
                }),
            }),
            addSpeaker: build.mutation<SpeakerPayload, string>({
                query: (name) => ({
                    url: `speakers/`,
                    method: 'POST',
                    body: { name },
                }),
                invalidatesTags: [{ type: 'Speakers' }],
            }),
            editSpeaker: build.mutation<SpeakerPayload, { id: number; name: string }>({
                query: ({ id, name }) => ({
                    url: `speakers/${id}/`,
                    method: 'PATCH',
                    body: { name },
                }),
                invalidatesTags: [{ type: 'Speakers' }],
            }),
            deleteSpeaker: build.mutation<SpeakerPayload, number>({
                query: (id) => ({
                    url: `speakers/${id}/`,
                    method: 'DELETE',
                }),
                invalidatesTags: [{ type: 'Speakers' }],
            }),
            speakers: build.query<SpeakersPayload, null>({
                query: () => ({
                    url: `speakers/`,
                    method: 'GET',
                }),
                providesTags: [{ type: 'Speakers' }],
            }),
            requests: build.query<RequestsPayload, null>({
                query: () => ({
                    url: `requests/`,
                    method: 'GET',
                }),
                providesTags: [{ type: 'Requests' }],
            }),
            revokeRequest: build.mutation<boolean, { taskId: string }>({
                query: ({ taskId }) => ({
                    url: `task/${taskId}/revoke/`,
                    method: 'GET',
                }),
                invalidatesTags: [{ type: 'Requests' }],
            }),
            results: build.query<ResultsPayload, null>({
                query: (param) => ({
                    url: `results/?success=true`,
                    method: 'GET',
                }),
                providesTags: [{ type: 'Results' }],
            }),
            filteredResults: build.query<ResultsPayload, { date?: [string, string] }>({
                query: ({ date }) => ({
                    url: `results/?success=true`,
                    params: {
                        ...(date ? { timestamp_after: date[0] } : {}),
                        ...(date ? { timestamp_before: date[1] } : {}),
                    },
                    method: 'GET',
                }),
                providesTags: [{ type: 'Results' }],
            }),
            result: build.query<TranscriptPayload, { transcriptId: string; shareAccessToken?: string }>({
                query: ({ transcriptId, shareAccessToken }) => ({
                    url: `result/${transcriptId}/`,
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${shareAccessToken || getAPIToken()}`,
                    },
                }),
                providesTags: [{ type: 'Result' }],
            }),
            editResult: build.mutation<
                TranscriptPayload,
                { req_id: string; result: EditResultPayload; shareAccessToken?: string }
            >({
                query: ({ req_id, result, shareAccessToken }) => ({
                    url: `result/${req_id}/`,
                    method: 'PUT',
                    body: result,
                    headers: {
                        Authorization: `Bearer ${shareAccessToken || getAPIToken()}`,
                    },
                }),
            }),
            deleteResult: build.mutation<boolean, string>({
                query: (req_id) => ({
                    url: `result/${req_id}/`,
                    method: 'DELETE',
                    body: req_id,
                }),
                invalidatesTags: [{ type: 'Results' }],
            }),
            getProtocol: build.query<{ summaries: TranscriptSummariesPayload; summarize_timestamp: string }, string>({
                query: (req_id) => ({
                    url: `result/${req_id}/`,
                    method: 'GET',
                }),
                transformResponse: (response: TranscriptPayload) => ({
                    summaries: response.summaries,
                    summarize_timestamp: response.summarize_timestamp,
                }),
            }),
            createProtocol: build.mutation<{ request_id: string; task_id: string }, string>({
                query: (req_id) => ({
                    url: `summarize/${req_id}/`,
                    method: 'GET',
                }),
                invalidatesTags: [{ type: 'Requests' }],
            }),
            downloadTranscriptFile: build.query<
                any,
                { req_id: string; doc_format: string; name: string; shareAccessToken?: string }
            >({
                query: ({ req_id, doc_format, name, shareAccessToken }) => ({
                    url: `result/${req_id}/download/${doc_format}/`,
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${shareAccessToken || getAPIToken()}`,
                    },
                    responseHandler: async (response) => {
                        if (response.status === 200) {
                            let blob = await response.blob();
                            const downloadUrl = window.URL.createObjectURL(blob);
                            const link = document.createElement('a');
                            link.href = downloadUrl;
                            link.download = `${name}.${doc_format}`;
                            document.body.appendChild(link);
                            link.click();
                            link.remove();
                        }
                    },
                }),
            }),
            search: build.query<SearchResultsPayload, string>({
                query: (searchString) => ({
                    url: `search/${searchString}/`,
                    method: 'GET',
                }),
            }),
            getComments: build.query<CommentProps[], string>({
                query: (req_id) => ({
                    url: `result/${req_id}/comments/`,
                    method: 'GET',
                }),
            }),
            addComment: build.mutation<void, { id: string; comment: CommentPayload }>({
                query: ({ id, comment }) => ({
                    url: `result/${id}/comments/`,
                    method: 'POST',
                    body: comment,
                }),
            }),
            getDictionariesList: build.query<DictionariesPayload, null>({
                query: () => ({
                    url: 'dict/',
                    method: 'GET',
                }),
                providesTags: [{ type: 'DictionariesList' }],
            }),
            deleteDictionary: build.mutation<void, number>({
                query: (id) => ({
                    url: `dict/${id}/`,
                    method: 'DELETE',
                }),
                invalidatesTags: [{ type: 'DictionariesList' }],
            }),
            createDictionary: build.mutation<DictionaryPayload, NewDictionaryPayload>({
                query: (body) => ({
                    url: `dict/`,
                    method: 'POST',
                    body: body,
                }),
                invalidatesTags: [{ type: 'DictionariesList' }],
            }),
            getDictionary: build.query<DictionaryPayload, string>({
                query: (dict_id) => ({
                    url: `dict/${dict_id}/`,
                    method: 'GET',
                }),
                providesTags: [{ type: 'Dictionary' }],
            }),
            addDictionaryWord: build.mutation<DictionaryWordPayload, NewDictionaryWordPayload>({
                query: (word) => ({
                    url: `dict/${word.dict}/items/`,
                    method: 'POST',
                    body: word,
                }),
                invalidatesTags: [{ type: 'Dictionary' }],
            }),
            deleteDictionaryWord: build.mutation<void, { dict: string; wordId: number }>({
                query: ({ dict, wordId }) => ({
                    url: `dict/${dict}/items/${wordId}/`,
                    method: 'DELETE',
                }),
                invalidatesTags: [{ type: 'Dictionary' }],
            }),
            editDictionaryWord: build.mutation<
                DictionaryWordPayload,
                { wordId: number; word: NewDictionaryWordPayload }
            >({
                query: ({ wordId, word }) => ({
                    url: `dict/${word.dict}/items/${wordId}/`,
                    method: 'PUT',
                    body: word,
                }),
                invalidatesTags: [{ type: 'Dictionary' }],
            }),
        };
    },
});

export const {
    useRecognizeFileMutation,
    useSpeakersQuery,
    useAddSpeakerMutation,
    useEditSpeakerMutation,
    useDeleteSpeakerMutation,
    useRequestsQuery,
    useLazyRequestsQuery,
    useRevokeRequestMutation,
    useResultsQuery,
    useLazyResultsQuery,
    useLazyFilteredResultsQuery,
    useLazyResultQuery,
    useEditResultMutation,
    useDeleteResultMutation,
    useLazyGetProtocolQuery,
    useCreateProtocolMutation,
    useLazyDownloadTranscriptFileQuery,
    useLazySearchQuery,
    useGetCommentsQuery,
    useGetDictionariesListQuery,
    useDeleteDictionaryMutation,
    useGetDictionaryQuery,
    useCreateDictionaryMutation,
    useAddDictionaryWordMutation,
    useDeleteDictionaryWordMutation,
    useEditDictionaryWordMutation,
} = apiApi;
