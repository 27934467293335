import classNames from 'classnames';
import React from 'react';
import './Stepper.scss';
interface StepperProps {
    step: number;
    stepsNames: string[];
}

const Stepper: React.FC<StepperProps> = ({ step, stepsNames }): JSX.Element => {
    return (
        <div className='stepper_wrapper'>
            <ol className='stepper'>
                {[
                    ...stepsNames.map((stepName: string, i: number) => {
                        return (
                            <React.Fragment key={i}>
                                {i !== 0 && <div className={classNames('line', { 'line--active': i <= step })}></div>}
                                <li className={`stepper__item ${i <= step ? 'stepper__item--current' : ''}`}>
                                    {stepName}
                                </li>
                            </React.Fragment>
                        );
                    }),
                ]}
            </ol>
        </div>
    );
};

export default Stepper;
