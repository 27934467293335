import React, { RefObject, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { RotatingLines } from 'react-loader-spinner';
import { toast } from 'react-toastify';

import {
    useAddSpeakerMutation,
    useDeleteSpeakerMutation,
    useEditSpeakerMutation,
    useSpeakersQuery,
} from 'api/routes/ApiApi';
import { ReactComponent as MenIcon } from 'assets/man.svg';
import { ReactComponent as EditIcon } from 'assets/pen-in-square.svg';
import { ReactComponent as DeleteIcon } from 'assets/trash-can.svg';
import { ErrorMessage } from 'components/generic/errorMessage/ErrorMessage';
import { Modal, ModalWithDimming } from 'components/generic/modal/Modal';
import SearchBar from 'components/generic/searchBar/SearchBar';

import './SpeakersPage.scss';

const SpeakersPage = () => {
    const [createNewSpeaker] = useAddSpeakerMutation();
    const [editSpeaker] = useEditSpeakerMutation();
    const [deleteSpeaker] = useDeleteSpeakerMutation();
    const {
        data: speakersData,
        isLoading: isSpeakersLoading,
        isError: isSpeakersError,
        isSuccess: isSpeakersSuccess,
    } = useSpeakersQuery(null, { refetchOnMountOrArgChange: true });

    const [searchText, setSearchText] = useState<string>('');
    const [modalNewSpeakerIsOpen, setModalNewSpeakerIsOpen] = useState<boolean>(false);
    const [visibleEditSpeakerModalId, setVisibleEditSpeakerModalId] = useState<number | null>(null);
    const [modalDeleteSpeakerIsOpen, setModalDeleteSpeakerIsOpen] = useState<boolean>(false);
    const [visibleDeleteSpeakerModalId, setVisibleDeleteSpeakerModalId] = useState<number | null>(null);

    const nameInputRef = useRef() as RefObject<HTMLInputElement>;

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            name: '',
            position: '',
        },
    });

    const renderModalNewSpeakerBody = () => (
        <React.Fragment>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    if (nameInputRef.current) {
                        createNewSpeaker(nameInputRef.current.value);
                        setModalNewSpeakerIsOpen(false);
                    }
                }}
                className='adding_speaker_form'
            >
                <input type='text' className='input' placeholder='Имя' ref={nameInputRef} />
                <button type='submit' className='btn btn_primary'>
                    Сохранить
                </button>
            </form>
        </React.Fragment>
    );

    const renderModalEditSpeakerBody = () => (
        <React.Fragment>
            <form
                onSubmit={handleSubmit((data) => {
                    if (visibleEditSpeakerModalId) {
                        editSpeaker({ id: visibleEditSpeakerModalId, name: data.name })
                            .unwrap()
                            .catch((e) => {
                                if (e.status === 403) {
                                    toast.error('Вы не можете изменить данные об этом спикере');
                                }
                            });
                        setVisibleEditSpeakerModalId(null);
                    }
                })}
                className='editing_speaker_form'
            >
                <div className='input_wrapper'>
                    <div className='label'>Имя</div>
                    <input {...register('name', { required: 'Обязательное поле' })} className='input' />
                    {errors.name && <ErrorMessage message={errors.name?.message} />}
                </div>
                <div className='input_wrapper'>
                    <div className='label'>Должность (необязательно)</div>
                    <input {...register('position')} className='input' />
                </div>
                <button type='submit' className='btn btn_primary'>
                    Сохранить
                </button>
            </form>
        </React.Fragment>
    );

    const renderModalDeleteSpeakerBody = () => (
        <>
            <p className='text_light'>Вы действительно хотите удалить этого спикера?</p>
            <p className='text_light'>Отменить это действие будет невозможно.</p>
            <div className='modal_actions'>
                <button
                    type='button'
                    className='cancel_button btn btn_light'
                    onClick={() => setModalDeleteSpeakerIsOpen(false)}
                >
                    Отменить
                </button>
                <button
                    type='button'
                    className='confirm_button btn btn_danger'
                    onClick={() => {
                        if (visibleDeleteSpeakerModalId) {
                            deleteSpeaker(visibleDeleteSpeakerModalId)
                                .unwrap()
                                .catch((e) => {
                                    if (e.status === 403) {
                                        toast.error('Вы не можете удалить этого спикера');
                                    }
                                });
                            setModalDeleteSpeakerIsOpen(false);
                            setVisibleEditSpeakerModalId(null);
                        }
                    }}
                >
                    Да, удалить
                </button>
            </div>
        </>
    );

    useEffect(() => {
        const currentSpeaker = speakersData?.results.find((el) => el.id === visibleEditSpeakerModalId);
        if (currentSpeaker) {
            setValue('name', currentSpeaker.name);
            // установить должность спикера, когда появится на бэке
            setValue('position', '');
        }
    }, [setValue, speakersData, visibleEditSpeakerModalId]);

    return (
        <div className='speakers_page'>
            <div className='page_title'>Реестр спикеров</div>
            <div className='page_container'>
                <div className='search_and_add_line'>
                    <SearchBar defaultText='Поиск' searchText={searchText} setSearchText={setSearchText} />
                    <div className='btn btn_white' onClick={() => setModalNewSpeakerIsOpen(true)}>
                        <MenIcon />
                        <span>Новый спикер</span>
                    </div>
                </div>
                <div className='speakers_list'>
                    {isSpeakersLoading && (
                        <RotatingLines strokeColor='#814feb' strokeWidth='5' animationDuration='0.75' width='40' />
                    )}
                    {isSpeakersError && (
                        <p style={{ color: 'red' }}>
                            Что-то пошло не так при загрузке списка спикеров.
                            <br />
                            Пожалуйста, попробуйте перезагрузить страницу.
                        </p>
                    )}
                    {isSpeakersSuccess &&
                        [...speakersData.results]
                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .map((speaker) => (
                                <div className='speaker_card' key={speaker.id}>
                                    <div className='info'>
                                        <p className='name'>{speaker.name}</p>
                                        <p className='count'>{speaker.items.length} записей</p>
                                    </div>
                                    <div className='actions'>
                                        {visibleEditSpeakerModalId === speaker.id && (
                                            <Modal
                                                hideModal={() => setVisibleEditSpeakerModalId(null)}
                                                modalBody={renderModalEditSpeakerBody()}
                                                modalTitle='Редактировать спикера'
                                            />
                                        )}
                                        <DeleteIcon
                                            className='icon'
                                            onClick={() => {
                                                setVisibleDeleteSpeakerModalId(speaker.id);
                                                setModalDeleteSpeakerIsOpen(true);
                                            }}
                                        />
                                        <EditIcon
                                            className='icon'
                                            onClick={() => {
                                                setVisibleEditSpeakerModalId(speaker.id);
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}
                </div>
            </div>
            {modalNewSpeakerIsOpen && (
                <ModalWithDimming
                    hideModal={() => setModalNewSpeakerIsOpen(false)}
                    modalBody={renderModalNewSpeakerBody()}
                    modalTitle='Добавить спикера'
                />
            )}
            {modalDeleteSpeakerIsOpen && visibleDeleteSpeakerModalId && (
                <ModalWithDimming
                    hideModal={() => setModalDeleteSpeakerIsOpen(false)}
                    modalBody={renderModalDeleteSpeakerBody()}
                    modalTitle='Удалить спикера'
                />
            )}
        </div>
    );
};

export default SpeakersPage;
