import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { RotatingLines } from 'react-loader-spinner';
import { Link } from 'react-router-dom';

import { appApi } from 'api/routes/AppApi';
import { ErrorMessage } from 'components/generic/errorMessage/ErrorMessage';
import { ValidatedInput } from 'components/generic/inputFields/InputFields';
import { UserNameAndEmailSchema } from 'components/generic/inputFields/InputFields.interfaces';

import './ProfileInfo.scss';

const MainInfo: React.FC = () => {
    const { data: userInfo, isSuccess: userInfoIsSuccess } = appApi.useGetUserInfoQuery();
    const [changeUserInfo, { error: updatedUserInfoError, isLoading: updatedUserInfoIsLoading }] =
        appApi.useChangeUserInfoMutation();

    const methods = useForm<UserNameAndEmailSchema>({
        mode: 'onChange',
        defaultValues: {
            first_name: '',
            last_name: '',
        },
    });

    useEffect(() => {
        if (userInfoIsSuccess) {
            methods.setValue('first_name', userInfo.first_name);
            methods.setValue('last_name', userInfo.last_name);
        }
    }, [methods, userInfo, userInfoIsSuccess]);

    return (
        <React.Fragment>
            <div className='profile_info_section'>
                <p className='section_title'>Основная информация</p>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(changeUserInfo)} className='user_info_form'>
                        <div className='error_loader_container'>
                            {updatedUserInfoError && <ErrorMessage message='Не удалось изменить данные' />}
                        </div>
                        <div className='input_wrapper'>
                            <label className='label'>Имя</label>
                            <ValidatedInput field='first_name' />
                            {methods.formState.errors.first_name && (
                                <ErrorMessage message={methods.formState.errors.first_name.message} />
                            )}
                        </div>
                        <div className='input_wrapper'>
                            <label className='label'>Фамилия</label>
                            <ValidatedInput field='last_name' />
                            {methods.formState.errors.last_name && (
                                <ErrorMessage message={methods.formState.errors.last_name.message} />
                            )}
                        </div>
                        <button type='submit' className='btn btn_primary' disabled={!methods.formState.isValid}>
                            {updatedUserInfoIsLoading ? (
                                <RotatingLines
                                    strokeColor='white'
                                    strokeWidth='5'
                                    animationDuration='0.75'
                                    width='30'
                                />
                            ) : (
                                'Сохранить изменения'
                            )}
                        </button>
                        <Link to='change-password' className='btn btn_secondary change_password_btn'>
                            Изменить пароль
                        </Link>
                    </form>
                </FormProvider>
            </div>
        </React.Fragment>
    );
};

export default MainInfo;
