import { RefObject, useRef, useState } from 'react';

import { NewDictionaryModalProps } from './Dictionary.interface';

import { useCreateDictionaryMutation } from 'api/routes/ApiApi';

import { ErrorMessage } from 'components/generic/errorMessage/ErrorMessage';
import { ModalWithDimming } from 'components/generic/modal/Modal';

const NewDictionaryModal: React.FC<NewDictionaryModalProps> = ({ setModalIsOpen }): JSX.Element => {
    const nameInputRef = useRef() as RefObject<HTMLInputElement>;
    const [error, setError] = useState<boolean>(false);

    const [createDict] = useCreateDictionaryMutation();

    const createHandle = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!nameInputRef.current?.value.length) {
            setError(true);
            return;
        }

        if (nameInputRef.current) {
            try {
                createDict({ name: nameInputRef.current.value, flags: 1 });
                setModalIsOpen(false);
            } catch (e) {
                console.log(e);
            }
        }
    };

    return (
        <ModalWithDimming
            hideModal={() => setModalIsOpen(false)}
            modalTitle='Создать словарь'
            modalBody={
                <form className='adding_dictionary_form' onSubmit={createHandle}>
                    <input
                        type='text'
                        className='input'
                        placeholder='Название'
                        ref={nameInputRef}
                        onChange={() => setError(false)}
                    />
                    {error && <ErrorMessage message={'Введите название словаря'} />}
                    <button type='submit' className='btn btn_primary'>
                        Сохранить
                    </button>
                </form>
            }
        />
    );
};

export default NewDictionaryModal;
