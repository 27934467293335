import { useEffect } from 'react';
import { Outlet, matchPath, useLocation, useNavigate } from 'react-router-dom';

import Header from './header/RecordsHeader';

import { removeAllFromLS } from 'helpers/LocalStorageActions';
import { useAppSelector } from 'hooks/useAppSelector';

const TemplatePage: React.FC = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const { isAuthorized } = useAppSelector((state) => state.user);
    const isArchivePath = matchPath('/archive/transcript/*', pathname);

    useEffect(() => {
        if (!isAuthorized) {
            navigate('/login', { state: pathname });
            removeAllFromLS();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthorized, navigate]);

    return (
        <>
            {!isArchivePath ? <Header /> : <></>}
            <Outlet />
        </>
    );
};

export default TemplatePage;
