import { PropsWithChildren, useCallback } from 'react';
import { RotatingLines } from 'react-loader-spinner';

import { toast } from 'react-toastify';

import { ViewerProps } from '../DemoProcessing.interface';

import { ReactComponent as CloseIcon } from 'assets/cross-bold.svg';
import { ReactComponent as CopyIcon } from 'assets/transcript/copy.svg';
import { ReactComponent as DownloadIcon } from 'assets/transcript/download.svg';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { changeDemoView } from 'store/reducers/transcriptions/transcriptAppearanceSlice';

const DemoViewer: React.FC<ViewerProps & PropsWithChildren> = ({
    title,
    startProcessing,
    isRequestSuccess,
    isRequestLoading,
    handleDownload,
    children,
}) => {
    const dispatch = useAppDispatch();

    const { demoTextForCopy } = useAppSelector((state) => state.transcriptAppearance);

    const unsecuredCopyToClipboard = useCallback((text: string) => {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            document.execCommand('copy');
            toast.success('Текст скопирован', { position: 'bottom-right' });
        } catch (err) {
            console.error('Unable to copy to clipboard', err);
        }
        document.body.removeChild(textArea);
    }, []);

    const handleCopyText = useCallback(() => {
        if (!children) {
            return;
        }

        const text = typeof children === 'string' ? children : demoTextForCopy;

        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard
                .writeText(text)
                .then((e) => toast.success('Текст скопирован', { position: 'bottom-right' }))
                .catch((e) => console.log(e));
        } else {
            unsecuredCopyToClipboard(text);
        }
    }, [children, demoTextForCopy, unsecuredCopyToClipboard]);

    return (
        <div className='demo-viewer'>
            <div className='demo-viewer__header'>
                <h2>{title}</h2>
                <div style={{ flex: 1 }}></div>
                {handleDownload && (
                    <button type='button' className='btn btn_with_icon' onClick={handleDownload}>
                        <span>Скачать</span>
                        <DownloadIcon />
                    </button>
                )}
                <button type='button' className='btn btn_with_icon' onClick={handleCopyText}>
                    <span>Копировать</span>
                    <CopyIcon />
                </button>
                <button className='btn btn_with_icon' onClick={() => dispatch(changeDemoView(null))}>
                    <span>Закрыть</span>
                    <CloseIcon />
                </button>
            </div>
            {isRequestLoading && (
                <div className='demo-viewer__spinner'>
                    <RotatingLines
                        strokeColor='#814feb'
                        strokeWidth='5'
                        animationDuration='0.75'
                        width='40'
                        visible={isRequestLoading}
                    />
                </div>
            )}
            {!isRequestSuccess && !isRequestLoading && (
                <button className='btn btn_primary demo-viewer__create-button' onClick={startProcessing}>
                    Сформировать
                </button>
            )}
            {isRequestSuccess && <div className='demo-viewer__text'>{children}</div>}
        </div>
    );
};

export default DemoViewer;
