import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TranscriptAccessState {
    isEditingAllowed: boolean;
    shareAccessToken: string | undefined;
}

const initialState: TranscriptAccessState = {
    isEditingAllowed: true,
    shareAccessToken: undefined,
};

const slice = createSlice({
    name: 'transcriptAccess',
    initialState: initialState,
    reducers: {
        setIsEditingAllowed(state, { payload }: PayloadAction<boolean>) {
            state.isEditingAllowed = payload;
        },
        setShareAccessToken(state, { payload }: PayloadAction<string>) {
            state.shareAccessToken = payload;
        },
        transcriptAccessReset: () => initialState,
    },
    extraReducers: (builder) => {},
});

export const { setIsEditingAllowed, setShareAccessToken, transcriptAccessReset } = slice.actions;
export default slice.reducer;
