import classNames from 'classnames';
import React, { useState } from 'react';

import { TranscriptWordProps } from 'components/views/transcriptionsList/Transcript.interface';
import { useAppSelector } from 'hooks/useAppSelector';

import './TranscriptWord.scss';

const TranscriptWord: React.FC<TranscriptWordProps> = ({
    index,
    text,
    confidence,
    taskMarker,
    timestamp,
    isFiltered,
}): JSX.Element => {
    const { fontSize } = useAppSelector((state) => state.transcriptAppearance);

    const [isFocused] = useState(false);

    return (
        <span
            id={isFiltered ? `founded_${index[0]}-${index[1]}` : undefined}
            style={{ fontSize: fontSize }}
            data-word={index[1]}
            data-from={timestamp[0]}
            data-to={timestamp[1]}
            className={classNames('replica__word', { 'replica__word--highlighted': isFocused })}
            tabIndex={1}
            dangerouslySetInnerHTML={{ __html: text }}
        ></span>
    );
};

export default TranscriptWord;
