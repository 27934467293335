import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { RotatingLines } from 'react-loader-spinner';

import { DemoProjectsViewerProps } from '../DemoProcessing.interface';

import { useGetProjectsInfoMutation } from 'api/routes/DemoApi';
import Checkbox from 'components/generic/checkbox/Checkbox';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setDemoTextForCopy } from 'store/reducers/transcriptions/transcriptAppearanceSlice';

const DemoProjectsViewer: React.FC<DemoProjectsViewerProps> = ({ shortInfo, projectsList, getTranscriptText }) => {
    const dispatch = useAppDispatch();

    const [getProjectsInfo, { data: projectsInfo, isLoading, isSuccess, isError }] = useGetProjectsInfoMutation();

    const [tabId, setTabId] = useState(0);
    const [checkedProjects, setCheckedProjects] = useState<string[]>([]);

    const request = useRef<any>();

    const preparedProjectsList = useMemo(
        () =>
            projectsList.map((projectName) => {
                let name = projectName;

                const regex = /Проект №\d*\s*/g;
                const match = projectName.match(regex);

                if (match && match.length > 0) {
                    const restOfString = projectName.substring(match[0].length);
                    name = restOfString.charAt(0).toUpperCase() + restOfString.slice(1);
                }

                return {
                    name,
                    original: projectName,
                };
            }),
        [projectsList],
    );

    const getCheckedProjectsInfo = useCallback(() => {
        request.current = getProjectsInfo({ projects: checkedProjects, text: getTranscriptText() });
        setCheckedProjects([]);
        setTabId(1);
    }, [checkedProjects, getProjectsInfo, getTranscriptText]);

    useEffect(() => {
        dispatch(
            setDemoTextForCopy(
                tabId && !!projectsInfo?.result
                    ? Object.values(projectsInfo?.result).join()
                    : `${shortInfo}\n\nПроекты:\n${preparedProjectsList.map((project) => project.name).join()}`,
            ),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectsInfo?.result, projectsList, tabId]);

    return (
        <div className='projects-viewer'>
            {tabId === 0 && (
                <>
                    <>{shortInfo}</>
                    <div className='projects-viewer__list'>
                        <h3>Проекты</h3>
                        {preparedProjectsList.map((project, i) => (
                            <Checkbox
                                key={i}
                                id={i}
                                checked={checkedProjects.includes(project.original)}
                                setChecked={(isChecked: boolean) => {
                                    setCheckedProjects((prev) =>
                                        isChecked
                                            ? [...prev, project.original]
                                            : prev.filter((p) => p !== project.original),
                                    );
                                }}
                                text={project.name}
                            />
                        ))}
                        <button
                            disabled={!checkedProjects.length}
                            className='btn btn_primary demo-viewer__create-button'
                            onClick={getCheckedProjectsInfo}
                        >
                            Получить
                        </button>
                    </div>
                </>
            )}
            {tabId === 1 && (
                <>
                    <div
                        className='back_arrow'
                        onClick={() => {
                            request.current?.abort();
                            request.current?.reset();
                            setTabId(0);
                        }}
                    >
                        Назад
                    </div>
                    {isLoading && (
                        <div className='projects-viewer__spinner'>
                            <RotatingLines
                                strokeColor='#814feb'
                                strokeWidth='5'
                                animationDuration='0.75'
                                width='40'
                                visible={isLoading}
                            />
                        </div>
                    )}
                    {isSuccess && !!projectsInfo && (
                        <>
                            {Object.values(projectsInfo.result).map((text, i) => (
                                <div key={i}>
                                    {i !== 0 && <div className='divider' />}
                                    {text}
                                </div>
                            ))}
                        </>
                    )}
                    {isError && <p>Что-то пошло не так</p>}
                </>
            )}
        </div>
    );
};

export default DemoProjectsViewer;
