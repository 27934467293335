import { useEffect, useRef } from 'react';
// import { Controller } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { Bars } from 'react-loader-spinner';
import { toast } from 'react-toastify';

import { useRecognizeFileMutation } from 'api/routes/ApiApi';
import { ReactComponent as ClearIcon } from 'assets/cross-in-circle.svg';
// import { ReactComponent as QuestionIcon } from 'assets/question-circle.svg';
import { ErrorMessage } from 'components/generic/errorMessage/ErrorMessage';
// import Toggle from 'components/generic/toggle/Toggle';
import { TranscriptInfoFormProps } from 'components/views/recordUploader/UploadingPage.interface';

import './TranscriptInfoForm.scss';
import { LS } from 'config/env';
import { TRANSCRIPT_NAME_LENGTH_MAX_LIMIT } from 'helpers/Constants';
import { getTimeFromTimestamp, getYMDDateFromTimestamp } from 'helpers/TimeFormatting';

const TranscriptInfoForm: React.FC<TranscriptInfoFormProps> = ({
    navigateToNextStep,
    handleCancelUpload,
    clearAllUploadedFiles,
    uploadedFiles,
}): JSX.Element => {
    const [sendToRecognize, { isLoading: recognizeLoading }] = useRecognizeFileMutation();

    const isNeedToStore = useRef(true);

    const initDefaultValues = () => {
        const values: { [key: string]: any } = {};

        uploadedFiles.forEach((el) => {
            values[el.id] = {};
            values[el.id][`user_path`] = `${getYMDDateFromTimestamp(Date.now())} ${getTimeFromTimestamp(
                Date.now(),
                true,
            )} ${el.name}`.slice(0, TRANSCRIPT_NAME_LENGTH_MAX_LIMIT);
            values[el.id][`enable_annotation`] = true;
            // values[el.id][`unknownSpeakersNumber`] = true;
        });

        return values;
    };

    const methods = useForm({
        mode: 'onChange',
        defaultValues: localStorage.getItem(LS.INFO_FORM)
            ? JSON.parse(localStorage.getItem(LS.INFO_FORM)!)
            : initDefaultValues(),
    });

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        // control,
        watch,
        formState: { errors },
    } = methods;

    const onReturnToBeginningClick = () => {
        isNeedToStore.current = false;
        handleCancelUpload();
    };

    useEffect(() => {
        return () => {
            if (isNeedToStore.current) {
                localStorage.setItem(LS.INFO_FORM, JSON.stringify(getValues()));
            } else {
                localStorage.removeItem(LS.INFO_FORM);
            }
        };
    }, [getValues]);

    return (
        <FormProvider {...methods}>
            <form
                onSubmit={handleSubmit((transcriptData) => {
                    Promise.all(
                        Object.entries(transcriptData).map(([key, value]) =>
                            sendToRecognize({ ...(value as object), fileId: key }).unwrap(),
                        ),
                    )
                        .then(() => {
                            toast.success('Расшифровка запущена');
                            navigateToNextStep();
                            clearAllUploadedFiles();
                            isNeedToStore.current = false;
                        })
                        .catch((error) => {
                            if (error.data?.code === 402) {
                                toast.error('Недостаточно средств на счете. Пополните баланс');
                            } else {
                                toast.error(
                                    'Что-то пошло не так. Пожалуйста, попробуйте снова позже или выберете другой файл',
                                );
                            }
                        });
                })}
                className='transcription_details_form'
            >
                {uploadedFiles.map((el) => (
                    <div key={el.id} className='item'>
                        <div className='input_wrapper'>
                            <label className='label'>Название стенограммы</label>
                            <input
                                {...register(`${el.id}.user_path`)}
                                className='input'
                                autoComplete='off'
                                maxLength={TRANSCRIPT_NAME_LENGTH_MAX_LIMIT}
                                required
                                autoFocus
                                style={{ padding: '6px 37px 6px 12px' }}
                            />
                            {watch(`${el.id}.user_path`)?.length > 0 && (
                                <ClearIcon className='clear_icon' onClick={() => setValue(`${el.id}.user_path`, '')} />
                            )}
                            {errors[`${el.id}.user_path`] && (
                                <ErrorMessage message={errors[`${el.id}.user_path`]?.message?.toString()} />
                            )}
                        </div>
                        <span>Максимальная длина: 50 символов</span>
                        {/* число спикеров временно не учитывается */}
                        {/* <div className='input_wrapper'>
                            <label className='label'>Число участников</label>
                            <div className='speakers_count_wrapper'>
                                <Controller
                                    control={control}
                                    name={`${el.id}.unknownSpeakersNumber`}
                                    render={({ field: { value, onChange } }) => (
                                        <>
                                            <input
                                                {...register(`${el.id}.num_speakers`)}
                                                className='input'
                                                min={1}
                                                type='number'
                                                required={!value}
                                                disabled={value}
                                            />
                                            <Toggle
                                                id='unknown-speakers-count'
                                                selected={value}
                                                setSelected={onChange}
                                                text='Неизвестно'
                                            />
                                        </>
                                    )}
                                />
                            </div>
                        </div> */}
                        {/* Скрыто на время показа */}
                        {/* <div className='diarization_wrapper'>
                            <Controller
                                control={control}
                                name={`${el.id}.enable_annotation`}
                                render={({ field: { value, onChange } }) => (
                                    <Toggle
                                        selected={value}
                                        id='diarization'
                                        setSelected={onChange}
                                        text='Использовать диаризацию'
                                    />
                                )}
                            />
                            <div className='tooltip'>
                                <QuestionIcon className='icon' />
                                <span className='tooltiptext'>
                                    Диаризация - разбиение текста на реплики на основе соответствия аудиопотока тому или
                                    иному говорящему
                                </span>
                            </div>
                        </div> */}
                    </div>
                ))}

                <button className='btn btn_primary' type='submit'>
                    {recognizeLoading ? (
                        <Bars
                            height='25'
                            width='30'
                            color='white'
                            ariaLabel='bars-loading'
                            wrapperStyle={{}}
                            wrapperClass=''
                        />
                    ) : (
                        'Начать расшифровку'
                    )}
                </button>
                <button className='btn btn_secondary' type='button' onClick={onReturnToBeginningClick}>
                    {false ? (
                        <Bars
                            height='25'
                            width='30'
                            color='white'
                            ariaLabel='bars-loading'
                            wrapperStyle={{}}
                            wrapperClass=''
                        />
                    ) : (
                        'Вернуться в начало'
                    )}
                </button>
            </form>
        </FormProvider>
    );
};

export default TranscriptInfoForm;
