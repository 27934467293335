import classNames from 'classnames';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { appApi } from 'api/routes/AppApi';
import { ReactComponent as Arrow } from 'assets/arrow-without-body.svg';
import avatar from 'assets/avatar-mock.png';
import add from 'assets/balance/add.svg';
import wallet from 'assets/balance/wallet.svg';
import { isLargeMobile } from 'config/env';
import { logout } from 'helpers/LogoutActions';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useOutsideClick from 'hooks/useOutsideClick';

import './UserDropdown.scss';

export default function UserDropdown() {
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const dispatch = useAppDispatch();

    const { data: userInfo } = appApi.useGetUserInfoQuery();

    useOutsideClick([dropdownRef], () => {
        setMenuIsOpen(false);
    });

    return (
        <div className='user-info_wrapper' ref={dropdownRef}>
            <div
                className={classNames('user-info', { 'user-info--active': menuIsOpen })}
                tabIndex={0}
                onClick={() => setMenuIsOpen(!menuIsOpen)}
            >
                <span className='name'>
                    {userInfo?.first_name} {userInfo?.last_name}
                </span>
                <Arrow className={`arrow ${menuIsOpen ? 'arrow--rotated' : ''}`} />
                <img src={avatar} className='avatar' alt='logo' />
            </div>
            <div className={classNames('dropdown_menu', { 'dropdown_menu--open': menuIsOpen })}>
                {isLargeMobile && (
                    <>
                        <div className='balance dropdown_menu__item'>
                            <img src={wallet} className='balance__icon' alt='wallet' />
                            <p className='balance__value'>13 000</p>
                            <Link to='/balance/receipt' onClick={() => setMenuIsOpen(false)}>
                                <img src={add} className='balance__icon' alt='add' />
                            </Link>
                        </div>
                        <div className='limiter_line'></div>
                    </>
                )}
                <Link to='/support' className='dropdown_menu__item' onClick={() => setMenuIsOpen(false)}>
                    Поддержка
                </Link>
                <Link to='/speakers' className='dropdown_menu__item' onClick={() => setMenuIsOpen(false)}>
                    Реестр спикеров
                </Link>
                <Link to='/dict' className='dropdown_menu__item' onClick={() => setMenuIsOpen(false)}>
                    Корпоративный словарь
                </Link>
                <Link to='/manual' className='dropdown_menu__item' onClick={() => setMenuIsOpen(false)}>
                    Руководство пользователя
                </Link>

                <Link to='/profile/info' className='dropdown_menu__item' onClick={() => setMenuIsOpen(false)}>
                    Личный кабинет
                </Link>
                <Link to='/' className='dropdown_menu__item' onClick={() => logout(dispatch)}>
                    Выйти
                </Link>
            </div>
        </div>
    );
}
