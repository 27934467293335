import { useCallback, useMemo } from 'react';

import { TranscriptPlayerProps } from './TranscriptPlayer.interface';

import Player from 'components/generic/player/Player';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { setAudioFragmentPlaying, setReplicaPlaying } from 'store/reducers/transcriptions/audioSlice';

const TranscriptPlayer: React.FC<TranscriptPlayerProps> = ({ audioUrl, duration }): JSX.Element => {
    const dispatch = useAppDispatch();

    const { playingTimestepRange, isFragmentPlaying, isReplicaPlaying, selectedTimestepRange } = useAppSelector(
        (state) => state.audio,
    );
    const audioSpeedChoose = [0.5, 0.75, 1, 1.5, 2];

    const setIsFragmentPlaying = useCallback(
        (isPlaying: boolean) => {
            dispatch(setAudioFragmentPlaying(isPlaying));

            if (isReplicaPlaying) {
                dispatch(setReplicaPlaying({ isPlaying: isPlaying }));
            }
        },
        [dispatch, isReplicaPlaying],
    );
    const audioFragment = useMemo(() => {
        return {
            isFragmentPlaying: isFragmentPlaying,
            setIsFragmentPlaying: setIsFragmentPlaying,
            // костыль на время, пока ML плохо определяет границы слов
            startTime: playingTimestepRange[0] && playingTimestepRange[0] - 0.2,
            endTime: playingTimestepRange[1],
        };
    }, [isFragmentPlaying, playingTimestepRange, setIsFragmentPlaying]);

    const currentAudioTimestamp = useMemo(
        // костыль на время, пока ML плохо определяет границы слов
        () => selectedTimestepRange[0] && selectedTimestepRange[0] - 0.2,
        [selectedTimestepRange],
    );

    return (
        <Player
            audioUrl={audioUrl}
            audioFragment={audioFragment}
            currentAudioTimestamp={currentAudioTimestamp}
            audioSpeedChoose={audioSpeedChoose}
            duration={duration}
            preload
        />
    );
};

export default TranscriptPlayer;
