import { removeAllFromLS } from './LocalStorageActions';

import { apiApi } from 'api/routes/ApiApi';
import { appApi } from 'api/routes/AppApi';
import { recordsApi } from 'api/routes/RecordsApi';
import { AppDispatch } from 'store';
import { setAuthorized } from 'store/reducers/user/userSlice';

export const logout = (dispatch: AppDispatch) => {
    removeAllFromLS();
    dispatch(setAuthorized(false));
    dispatch(recordsApi.util.resetApiState());
    dispatch(apiApi.util.resetApiState());
    dispatch(appApi.util.resetApiState());
};
