/* eslint-disable no-unused-vars */
export const TRANSCRIPT_NAME_LENGTH_MAX_LIMIT = 50;

export const CONTENT_PER_PAGE = [1, 2, 3, 5, 10, 20, 50];

export const MILLISECONDS_PER_SECOND = 1000;

export const MONTHS = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
];

export const MONTHS_ABBREVIATIONS = [
    'янв.',
    'фев.',
    'мар.',
    'апр.',
    'май',
    'июн.',
    'июл.',
    'авг.',
    'сен.',
    'окт.',
    'ноя.',
    'дек.',
];

export const WEEKDAY = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

export const sortProperty = {
    date: 'date',
    name: 'name',
};

export const sortOrder = {
    asc: 'asc',
    desc: 'desc',
};

export const sortingList = [
    { label: 'Дате создания', sortProperty: sortProperty.date, id: 0 },
    { label: 'Названию', sortProperty: sortProperty.name, id: 1 },
];

export const folderActionType = { edit: 'edit', delete: 'delete', create: 'create' };

export const folderActionTypeLabels = {
    [folderActionType.edit]: 'Редактирование',
    [folderActionType.delete]: 'Удаление',
    [folderActionType.create]: 'Создание',
};

export const calendarModes = {
    date: 'date',
    period: 'period',
};

export const allowedTypes = ['audio', 'video'];

export const allowedExtensions = [
    'aac',
    'ac3',
    'avi',
    'bin',
    'codec2',
    'flv',
    'm4v',
    'webm',
    'mlv',
    'mp4',
    'mp3',
    'ogg',
    'oss',
    'vmd',
    'wav',
    'xmv',
    'mkv',
];

export enum ShareLevel {
    Private = 1,
    Group = 2,
    Anonymous = 3,
}

export enum ShareGrant {
    Read = 1,
    Comment = 2,
    Edit = 3,
}

export const SHARE_GRANT_LABEL = {
    [ShareGrant.Read]: 'Чтение',
    [ShareGrant.Comment]: 'Комментирование',
    [ShareGrant.Edit]: 'Редактирование',
};

export const SHARE_GRANT_DESCRIPTION = {
    [ShareGrant.Read]: 'Все пользователи, у которых есть ссылка, могут просматривать и скачивать текст стенограммы',
    [ShareGrant.Comment]:
        'Все пользователи, у которых есть ссылка, могут просматривать, скачивать и комментировать текст стенограммы',
    [ShareGrant.Edit]:
        'Все пользователи, у которых есть ссылка, могут просматривать, скачивать, комментировать и редактировать текст стенограммы',
};

export enum UserRoles {
    Reader = 'reader',
    Editor = 'editor',
}

export const ROLES_LABEL = {
    [UserRoles.Reader]: 'Читатель',
    [UserRoles.Editor]: 'Редактор',
};

export const ROLES_DESCRIPTION = {
    [UserRoles.Reader]:
        'Все пользователи, которым открыт доступ к расшифровке, могут просматривать и скачивать текст стенограммы',
    [UserRoles.Editor]:
        'Все пользователи, которым открыт доступ к расшифровке, могут просматривать, редактировать и скачивать текст стенограммы, а также скачивать аудио стенограммы ',
};
