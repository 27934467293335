import { useState } from 'react';
import { RotatingLines } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';

import NewDictionaryModal from './NewDictionaryModal';

import { useDeleteDictionaryMutation, useGetDictionariesListQuery } from 'api/routes/ApiApi';

import { ReactComponent as DictionaryIcon } from 'assets/transcript/dict.svg';
import { ReactComponent as DeleteIcon } from 'assets/trash-can.svg';

import { ModalWithDimming } from 'components/generic/modal/Modal';
import SearchBar from 'components/generic/searchBar/SearchBar';

import './DictionaryList.scss';

const DictionaryList: React.FC = (): JSX.Element => {
    const {
        data: dictionaries,
        isLoading: isDictionariesLoading,
        error: isDictionariesError,
        isSuccess: isDictionariesSuccess,
    } = useGetDictionariesListQuery(null, { refetchOnMountOrArgChange: true });
    const [deleteDict] = useDeleteDictionaryMutation();

    const navigate = useNavigate();

    const [visibleDictId, setVisibleDictId] = useState<number | null>(null);
    const [searchText, setSearchText] = useState<string>('');
    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    const renderDeleteModalBody = () => (
        <>
            <p className='text_light'>Вы действительно хотите удалить этот словарь?</p>
            <p className='text_light'>Отменить это действие будет невозможно.</p>
            <div className='modal_actions'>
                <button
                    type='button'
                    className='cancel_button btn btn_light'
                    onClick={() => setDeleteModalIsOpen(false)}
                >
                    Отменить
                </button>
                <button
                    type='button'
                    className='confirm_button btn btn_danger'
                    onClick={() => {
                        if (visibleDictId) {
                            deleteDict(visibleDictId)
                                .unwrap()
                                .catch((e) => {
                                    if (e.status === 403) {
                                        toast.error('Вы не можете удалить этот словарь');
                                    }
                                });
                            setCreateModalIsOpen(false);
                            setVisibleDictId(null);
                        }
                    }}
                >
                    Да, удалить
                </button>
            </div>
        </>
    );

    return (
        <div className='dictionaries_page'>
            <h2 className='page_title'>Словари</h2>
            <div className='page_container'>
                <div className='search_and_add_line'>
                    <SearchBar defaultText='Поиск' searchText={searchText} setSearchText={setSearchText} />
                    <div className='btn btn_white' onClick={() => setCreateModalIsOpen(true)}>
                        <DictionaryIcon />
                        <span>Добавить словарь</span>
                    </div>
                </div>
                <div className='dictionaries_list'>
                    {isDictionariesLoading && (
                        <RotatingLines strokeColor='#814feb' strokeWidth='5' animationDuration='0.75' width='40' />
                    )}
                    {isDictionariesError && (
                        <p style={{ color: 'red' }}>
                            Что-то пошло не так при загрузке списка словарей.
                            <br />
                            Пожалуйста, попробуйте перезагрузить страницу.
                        </p>
                    )}
                    {isDictionariesSuccess && dictionaries?.results.length === 0 && <h2>Нет словарей</h2>}
                    {isDictionariesSuccess &&
                        dictionaries?.results.map((dict, i) => {
                            return (
                                <div key={i} className='dictionary_card'>
                                    <div className='info' onClick={() => navigate(`/dict/${dict.id}`)}>
                                        <p className='name'>{dict.name}</p>
                                        <p className='count'>{dict.items.length} слов</p>
                                    </div>
                                    <div className='actions'>
                                        <DeleteIcon
                                            className='icon'
                                            onClick={() => {
                                                setVisibleDictId(dict.id);
                                                setDeleteModalIsOpen(true);
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
            {createModalIsOpen && <NewDictionaryModal setModalIsOpen={setCreateModalIsOpen} />}
            {deleteModalIsOpen && visibleDictId && (
                <ModalWithDimming
                    hideModal={() => setDeleteModalIsOpen(false)}
                    modalBody={renderDeleteModalBody()}
                    modalTitle='Удалить словарь'
                />
            )}
        </div>
    );
};

export default DictionaryList;
