import { ReactNode } from 'react';
import ReactDom from 'react-dom';

import CrossButton from 'components/generic/crossButton/CrossButton';

import './Modal.scss';

interface ModalProps {
    hideModal: () => void;
    modalBody: ReactNode;
    modalFooter?: ReactNode;
    modalTitle: string;
    modalBodyClassname?: string;
    modalTitleClassname?: string;
}

export const ModalWithDimming: React.FC<ModalProps> = ({
    hideModal,
    modalBody,
    modalTitle,
    modalFooter,
    modalBodyClassname = '',
    modalTitleClassname = '',
}) => {
    return ReactDom.createPortal(
        <div className='fullscreen' onClick={hideModal}>
            <div className={`modal_content ${modalBodyClassname}`} onClick={(event) => event.stopPropagation()}>
                <div className={`modal_title ${modalTitleClassname}`}>
                    <p className='text_dark text_bold'>{modalTitle}</p>
                    <CrossButton handleClick={hideModal} />
                </div>
                <div className='modal_body'>{modalBody}</div>
                {modalFooter && <div className='modal_footer'>{modalFooter}</div>}
            </div>
        </div>,
        document.getElementById('portal')!,
    );
};

export const Modal: React.FC<ModalProps> = ({ hideModal, modalBody, modalTitle }) => {
    return (
        <div className='modal_content' onClick={(event) => event.stopPropagation()}>
            <div className='modal_title'>
                <p className='text_dark text_bold'>{modalTitle}</p>
                <CrossButton handleClick={hideModal} />
            </div>
            <div className='modal_body'>{modalBody}</div>
        </div>
    );
};
