import classNames from 'classnames';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { inputTypes, ValidatedInputProps, ValidatedTextareaProps } from './InputFields.interfaces';

import { ReactComponent as Eye } from 'assets/eye.svg';

export const ValidatedInput: React.FC<ValidatedInputProps> = ({
    minLength,
    maxLength,
    min,
    max,
    field,
    pattern,
    required,
    validateValue,
    abilityToHide,
    disabled,
    type,
    className,
    placeholder,
    autofocus,
    autocompleteOff,
}) => {
    const [fieldIsHidden, setFieldIsHidden] = useState(true);
    const { register } = useFormContext();

    const registerOptions = {
        ...(required ? { required: 'Обязательное поле' } : {}),
        ...(pattern
            ? {
                  pattern: {
                      value: pattern,
                      message: 'Введите корректное значение',
                  },
              }
            : {}),
        ...(minLength
            ? {
                  minLength: {
                      value: minLength,
                      message: `Длина не должна быть меньше ${minLength} символов`,
                  },
              }
            : {}),
        ...(maxLength
            ? {
                  maxLength: {
                      value: maxLength,
                      message: `Длина не должна быть больше ${maxLength} символов`,
                  },
              }
            : {}),
        ...(min
            ? {
                  min: {
                      value: min,
                      message: `Значение не должно быть меньше ${min}`,
                  },
              }
            : {}),
        ...(max
            ? {
                  max: {
                      value: max,
                      message: `Значение не должно превышать ${max}`,
                  },
              }
            : {}),
        ...(validateValue
            ? {
                  validate: (value: string) => value === validateValue || 'Пароли не совпадают',
              }
            : {}),
    };

    return (
        <>
            <input
                {...register(field, registerOptions)}
                placeholder={placeholder}
                autoFocus={autofocus || false}
                disabled={disabled || false}
                className={classNames('input', { [`${className}`]: className })}
                min={min}
                max={max}
                autoComplete={autocompleteOff ? 'off' : 'on'}
                type={
                    (type && type !== inputTypes.password) || (type === inputTypes.password && fieldIsHidden)
                        ? type
                        : 'text'
                }
            />
            {abilityToHide && (
                <Eye
                    className={classNames('showing_control', { 'showing_control--inactive': fieldIsHidden })}
                    onClick={() => {
                        setFieldIsHidden(!fieldIsHidden);
                    }}
                />
            )}
        </>
    );
};
export const ValidatedTextarea: React.FC<ValidatedTextareaProps> = ({
    minLength,
    maxLength,
    field,
    required,
    disabled,
    className,
    placeholder,
}) => {
    const { register } = useFormContext();

    const registerOptions = {
        ...(required ? { required: 'Обязательное поле' } : {}),
        ...(minLength
            ? {
                  minLength: {
                      value: minLength,
                      message: `Длина не должна быть меньше ${minLength} символов`,
                  },
              }
            : {}),
        ...(maxLength
            ? {
                  minLength: {
                      value: maxLength,
                      message: `Длина не должна быть больше ${maxLength} символов`,
                  },
              }
            : {}),
    };

    return (
        <>
            <textarea
                {...register(field, registerOptions)}
                placeholder={placeholder}
                disabled={disabled || false}
                className={classNames('input', { [`${className}`]: className })}
            />
        </>
    );
};
