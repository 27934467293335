import img1 from 'assets/features/1.svg';
import img2 from 'assets/features/2.svg';
import rocket from 'assets/features/rocket.svg';

import './Features.scss';

const Features: React.FC = () => (
    <div id='features' className='landing-section'>
        <div className='content'>
            <div className='features'>
                <div className='features__item'>
                    <img className='features__item-img features__item-img_big' src={img1} alt='feature 1' />
                    <div className='features__item-subtitle'>Точная расшифровка любых разговоров</div>
                </div>
                <div className='features__item'>
                    <img className='features__item-img' src={img2} alt='feature 2' />
                    <div className='features__item-subtitle'>
                        Позволяет сосредоточиться на обсуждении, а не на протоколировании
                    </div>
                </div>
            </div>
            <div className='sub-info'>
                <div className='sub-info__divider'>
                    <div className='sub-info__divider-line'></div>
                    <img className='sub-info__divider-img' src={rocket} alt='rocket' />
                </div>
                <div className='sub-info__text'>Разговор в текст на высокой скорости</div>
            </div>
        </div>
    </div>
);

export default Features;
