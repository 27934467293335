import RecordsList from 'components/views/recordsList/RecordsList';

const RecordsFoldersContainer: React.FC = () => {
    return (
        <div className='records_folders_container' style={{ width: '100%' }}>
            {/* закомментировано до появления папок на бэке */}
            {/* <FoldersList /> */}
            <RecordsList />
        </div>
    );
};

export default RecordsFoldersContainer;
