import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ReplicaProps, ReplicaWord } from './Transcript.interface';
import TranscriptControlPanel from './transcriptControlPanel/TranscriptControlPanel';
import TranscriptItem from './transcriptItem/TranscriptItem';
import TranscriptSearch from './transcriptSearch/TranscriptSearch';

import { TranscriptResultPayload } from 'api/Models';
import { useEditResultMutation } from 'api/routes/ApiApi';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { setSaved } from 'store/reducers/transcriptions/transcriptSlice';

const TranscriptList: React.FC = (): JSX.Element => {
    const dispatch = useAppDispatch();

    const [sendEditResult] = useEditResultMutation();
    const { transcript, editedTranscript, filteredTranscript, versionChanges } = useAppSelector(
        (state) => state.transcript,
    );
    const { shareAccessToken } = useAppSelector((state) => state.transcriptAccess);

    const [isAudioControlDisplay, setIsAudioControlDisplay] = useState<boolean>(false);
    const transcriptRef = useRef<HTMLDivElement>(null);
    const isMounted = useRef<boolean>(false);

    const handleSave = useCallback(async () => {
        try {
            const editedResult: ReplicaProps[] = structuredClone(editedTranscript?.result[0].result);
            editedResult.forEach((replica: ReplicaProps) => {
                replica.words.forEach((word: ReplicaWord) => (word[0] = word[0].trim()));

                replica.words.filter((word: ReplicaWord) => word[0] !== '');
            });

            const result = editedResult.map((replica) => ({
                words: replica.words,
                speaker: replica.speaker,
                timestep: replica.timestep,
            }));

            const mainResult: TranscriptResultPayload[] = [
                {
                    result: result,
                    channel: transcript.result[0].channel,
                },
            ];

            await sendEditResult({
                req_id: transcript.session_id || '',
                result: { result: mainResult },
                shareAccessToken,
            }).unwrap();
            dispatch(setSaved({ isSaved: true, timestamp: Date.now() }));
        } catch (e) {
            console.log(e);
        }
    }, [
        dispatch,
        editedTranscript?.result,
        sendEditResult,
        shareAccessToken,
        transcript?.result,
        transcript?.session_id,
    ]);

    useEffect(() => {
        if (isMounted.current) {
            if (versionChanges.length > 1) {
                const delayDebounceFn = setTimeout(() => {
                    dispatch(setSaved({ isSaved: false, timestamp: Date.now() }));
                    handleSave();
                }, 1000);

                return () => clearTimeout(delayDebounceFn);
            }
        } else {
            isMounted.current = true;
        }
    }, [dispatch, handleSave, versionChanges.length]);

    const moveToReplica = useCallback((replicaIdx: number, toEnd?: boolean) => {
        const s = document.getSelection();
        const range = document.createRange();

        const replica = transcriptRef.current?.getElementsByClassName('replica_text')[replicaIdx];
        const replicaWords = replica?.getElementsByClassName('replica__word');

        if (replicaWords) {
            const replicaText = replicaWords[toEnd ? replicaWords.length - 1 : 0].firstChild;

            if (replicaText && replicaText.nodeValue) {
                range.setStart(replicaText, toEnd ? replicaText.nodeValue.length : 0);
                s?.removeAllRanges();
                s?.addRange(range);
            }
        }
    }, []);

    const replicas = useMemo(() => {
        if (editedTranscript && 'result' in editedTranscript) {
            return editedTranscript?.result[0].result.map((replica: ReplicaProps, i) => (
                <TranscriptItem
                    key={replica.id}
                    index={i}
                    replica={replica}
                    filteredReplica={filteredTranscript?.result[0].result[i]}
                    setIsAudioControlDisplay={setIsAudioControlDisplay}
                    moveToReplica={moveToReplica}
                />
            ));
        }
    }, [editedTranscript, filteredTranscript?.result, moveToReplica]);

    return (
        <>
            <TranscriptSearch />
            <div role='textbox' className='transcript_list' ref={transcriptRef}>
                {replicas}
            </div>
            <TranscriptControlPanel
                isAudioControlDisplay={isAudioControlDisplay}
                setIsAudioControlDisplay={setIsAudioControlDisplay}
            />
        </>
    );
};

export default TranscriptList;
