import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { FilesPayload, FileUploadResultPayload, FilePayload } from 'api/Models';
import { getAPIToken } from 'helpers/LocalStorageActions';

const RECORDS_API_HOST = process.env.REACT_APP_RECORDS_API_HOST;

export const recordsApi = createApi({
    reducerPath: 'recordsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${RECORDS_API_HOST}/api/`,
        prepareHeaders: (headers) => {
            const token = getAPIToken();
            if (!headers.has('authorization') && token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),

    endpoints: (build) => {
        return {
            getFiles: build.query<FilesPayload, null>({
                query: () => ({
                    url: '/files/',
                    method: 'GET',
                }),
            }),
            getFileMetadata: build.query<FilePayload, { fileId: string; shareAccessToken?: string }>({
                query: ({ fileId, shareAccessToken }) => ({
                    url: `file/metadata/${fileId}/`,
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${shareAccessToken || getAPIToken()}`,
                    },
                }),
            }),
            uploadFile: build.mutation<FileUploadResultPayload, FormData>({
                query: (formData) => ({
                    url: '/file/upload/',
                    method: 'PUT',
                    body: formData,
                }),
            }),
            deleteFile: build.mutation<boolean, string>({
                query: (id) => ({
                    url: `file/delete/${id}/`,
                    method: 'DELETE',
                    body: id,
                }),
            }),
            downloadFileById: build.query<any, { fileId: string; shareAccessToken?: string }>({
                query: ({ fileId, shareAccessToken }) => ({
                    url: `file/download/${fileId}/`,
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${shareAccessToken || getAPIToken()}`,
                    },
                    responseHandler: async (response) => {
                        let blob = await response.blob();
                        const downloadUrl = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = downloadUrl;
                        // IMPORTANT FIX заменить на название стенограммы
                        link.download = `file_${Date.now()}.mp3`;
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                    },
                }),
            }),
        };
    },
});

export const {
    useGetFilesQuery,
    useLazyGetFileMetadataQuery,
    useLazyGetFilesQuery,
    useUploadFileMutation,
    useDeleteFileMutation,
    useLazyDownloadFileByIdQuery,
} = recordsApi;
