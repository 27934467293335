import { RotatingLines } from 'react-loader-spinner';
import { Link, useParams } from 'react-router-dom';

import { useConfirmAccountQuery } from 'api/routes/AppApi';

const SuccessfulConfirmation: React.FC = (): JSX.Element => {
    let { token } = useParams();

    const { data: confirmResult, isLoading, isError } = useConfirmAccountQuery(token || '');

    return (
        <div className='successful_confirmation_container'>
            {confirmResult === true && (
                <>
                    <p>Аккаунт успешно зарегистрирован.</p>
                    <Link to='/login' className='link'>
                        <button className='btn btn_secondary'>Войти</button>
                    </Link>
                </>
            )}
            {isLoading && <RotatingLines strokeColor='white' strokeWidth='5' animationDuration='0.75' width='30' />}
            {isError && <p>Ошибка активации учетной записи</p>}
        </div>
    );
};

export default SuccessfulConfirmation;
