import classNames from 'classnames';

import { ToggleProps } from './Toggle.interface';

import './Toggle.scss';

const Toggle: React.FC<ToggleProps> = ({ id, selected, setSelected, text }: ToggleProps): JSX.Element => {
    return (
        <div className='toggle_wrapper'>
            <div
                className={classNames('default_toggle', { 'default_toggle--active': selected })}
                onClick={() => setSelected(!selected)}
            >
                <div className='default_toggle_button'> </div>
            </div>
            <label htmlFor={id} className='default_toggle_label'>
                {text}
            </label>
        </div>
    );
};

export default Toggle;
