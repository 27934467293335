import { endOfDay, startOfDay } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';

import Calendar from './calendar/Calendar';

import {
    useLazySearchQuery,
    useLazyResultsQuery,
    useLazyRequestsQuery,
    useLazyFilteredResultsQuery,
} from 'api/routes/ApiApi';
// TODO: TAS-1007
// import { useLazyGetUserBalanceInfoQuery } from 'api/routes/AppApi';
import { useLazyGetFilesQuery } from 'api/routes/RecordsApi';
import SearchBar from 'components/generic/searchBar/SearchBar';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setFilteredDate } from 'store/reducers/archive/archiveSlice';
import { setArchiveFilterWord } from 'store/reducers/transcriptions/transcriptSlice';

import './SearchFilter.scss';

const SearchFilter: React.FC = (): JSX.Element => {
    const dispatch = useAppDispatch();

    const [searchDate, setSearchDate] = useState<[string, string] | null>(null);
    const [searchDateRange, setSearchDateRange] = useState<[string, string] | null>(null);
    const [searchText, setSearchText] = useState<string>('');
    const [getSearchResult] = useLazySearchQuery();
    const [getAllRecords] = useLazyResultsQuery();
    const [getFilteredRecords] = useLazyFilteredResultsQuery();
    const [getAllRequests] = useLazyRequestsQuery();
    const [getAllFiles] = useLazyGetFilesQuery();
    // TODO: TAS-1007
    // const [getBalance] = useLazyGetUserBalanceInfoQuery();

    const handleSearchDate = useCallback((date: Date | null) => {
        setSearchDate(date && [startOfDay(date).toISOString(), endOfDay(date).toISOString()]);
    }, []);

    const handleSearchDateRange = useCallback((date: [Date, Date] | null) => {
        setSearchDateRange(date && [startOfDay(date[0]).toISOString(), endOfDay(date[1]).toISOString()]);
    }, []);

    useEffect(() => {
        if (!searchText.length && !searchDate && !searchDateRange) {
            dispatch(setArchiveFilterWord(null));
            dispatch(setFilteredDate(null));
            getAllFiles(null);
            getAllRequests(null);
            getAllRecords(null);
            // TODO: TAS-1007
            // getBalance(null);
            return;
        }
        const delayDebounceFn = setTimeout(async () => {
            const filterDate = searchDate ? searchDate : searchDateRange;

            dispatch(setArchiveFilterWord(searchText));
            dispatch(setFilteredDate(filterDate));

            if (searchText) {
                await getSearchResult(searchText).unwrap();
            } else {
                filterDate
                    ? await getFilteredRecords({ date: filterDate }).unwrap()
                    : await getAllRecords(null).unwrap();
            }
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [
        dispatch,
        getAllFiles,
        getAllRecords,
        getAllRequests,
        // TODO: TAS-1007
        // getBalance,
        getFilteredRecords,
        getSearchResult,
        searchDate,
        searchDateRange,
        searchText,
    ]);

    return (
        <div className='search_filter'>
            <SearchBar defaultText='Текст или название' searchText={searchText} setSearchText={setSearchText} />
            <Calendar setSearchDate={handleSearchDate} setSearchDateRange={handleSearchDateRange} />
        </div>
    );
};

export default SearchFilter;
