import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PaginationState {
    firstContentIndex: number;
    lastContentIndex: number;
    numContentPerPage: number;
}

const initialState: PaginationState = {
    firstContentIndex: 0,
    lastContentIndex: 0,
    numContentPerPage: 5,
};

export const paginationSlice = createSlice({
    name: 'pagination',
    initialState,
    reducers: {
        setFirstContentIndex: (state, action: PayloadAction<number>) => {
            state.firstContentIndex = action.payload;
        },
        setLastContentIndex: (state, action: PayloadAction<number>) => {
            state.lastContentIndex = action.payload;
        },
        setNumContentPerPage: (state, action: PayloadAction<number>) => {
            state.numContentPerPage = action.payload;
        },
    },
    extraReducers: {},
});

export const { setFirstContentIndex, setLastContentIndex, setNumContentPerPage } = paginationSlice.actions;

export default paginationSlice.reducer;
