import { useCallback, useEffect, useState } from 'react';
import { RotatingLines } from 'react-loader-spinner';
import { useSearchParams } from 'react-router-dom';

import { toast } from 'react-toastify';

import RequestItem from './requestItem/RequestItem';

import { CompletedSearchTranscriptPayload, CompletedTranscriptPayload } from 'api/Models';
import { useRequestsQuery, useResultsQuery } from 'api/routes/ApiApi';
// TODO: TAS-1007
// import { useGetUserBalanceInfoQuery } from 'api/routes/AppApi';
import { useGetFilesQuery } from 'api/routes/RecordsApi';
import RecordItem from 'components/views/recordsList/recordItem/RecordItem';
import { MONTHS, sortOrder, sortProperty } from 'helpers/Constants';
import { useAppSelector } from 'hooks/useAppSelector';

import './RecordsList.scss';

const RecordsList: React.FC = (): JSX.Element => {
    const { records, requests, filteredRecords, isFiltered, isLoading } = useAppSelector((state) => state.archive);
    const [sortedRecords, setSortedRecords] = useState<CompletedTranscriptPayload[]>([]);
    const [sortedSearchRecords, setSortedSearchRecords] = useState<CompletedSearchTranscriptPayload[]>([]);
    const [searchParams] = useSearchParams();
    const { isSuccess: isArchiveSuccess, isError: isArchiveError } = useResultsQuery(null, {
        pollingInterval: isFiltered ? undefined : 60000,
    });
    const { isSuccess: isRequestsSuccess, isError: isRequestsError } = useRequestsQuery(null, {
        pollingInterval: isFiltered ? undefined : 60000,
    });
    const {
        data: files,
        isSuccess: isFilesSuccess,
        isError: isFilesError,
    } = useGetFilesQuery(null, {
        pollingInterval: isFiltered ? undefined : 60000,
    });

    // TODO: TAS-1007
    // useGetUserBalanceInfoQuery(null, {
    //     pollingInterval: isFiltered ? undefined : 60000,
    // });

    // const { folders } = useAppSelector((state) => state.folders);

    const sortingRecords = useCallback(
        (completedTranscriptInfo: CompletedTranscriptPayload[] | CompletedSearchTranscriptPayload[]) => {
            // закомментровано до появления папок на бэке
            // const folderProperty = searchParams.get('folder');
            // const isFolderOpen: boolean = folderProperty !== null && !isNaN(Number(folderProperty));

            const sortingProperty = searchParams.get('sort');
            const sortingOrder = searchParams.get('order') === sortOrder.desc ? 1 : -1;

            let sortedRecords = [...completedTranscriptInfo];
            switch (sortingProperty) {
                case sortProperty.date:
                    sortedRecords = [...completedTranscriptInfo].sort(
                        (var1, var2) =>
                            (new Date(var2.timestamp).getTime() - new Date(var1.timestamp).getTime()) * sortingOrder,
                    );

                    break;
                case sortProperty.name:
                    sortedRecords = [...completedTranscriptInfo].sort(
                        (var1, var2) => var1.name.localeCompare(var2.name) * sortingOrder,
                    );
                    break;
                default:
                    sortedRecords = [...completedTranscriptInfo].sort(
                        (var1, var2) =>
                            new Date(var2.timestamp).getTime() - new Date(var1.timestamp).getTime() * sortingOrder,
                    );
            }

            // закомментровано до появления папок на бэке
            // if (isFolderOpen) {
            //     const folderPropertyNumber = Number(folderProperty);
            //     sortedRecords = sortedRecords.filter((el) => el.folder_id === folderPropertyNumber);
            // }

            return sortedRecords;
        },
        [searchParams],
    );

    useEffect(() => {
        if (!isFiltered) {
            const completedTranscriptInfo: CompletedTranscriptPayload[] = records.map((el) => {
                const file = files?.results.find((file) => file.file_id === el.file_id);
                const name = el.user_path[el.user_path.length - 1] || '';
                const stream_url = file ? file.stream_url : '';
                return { ...el, name, stream_url } as CompletedTranscriptPayload;
            });

            // allRecords = allRecords.concat(sortedRecords);
            setSortedRecords(sortingRecords(completedTranscriptInfo) as CompletedTranscriptPayload[]);
        }
    }, [files?.results, isFiltered, records, sortingRecords]);

    useEffect(() => {
        if (isFiltered) {
            const completedTranscriptInfo: CompletedSearchTranscriptPayload[] = filteredRecords.map((el) => {
                const file = files?.results.find((file) => file.file_id === el.file_id);
                const name = el.user_path[el.user_path.length - 1] || '';
                const stream_url = file ? file.stream_url : '';
                return { ...el, name, stream_url } as CompletedSearchTranscriptPayload;
            });

            setSortedSearchRecords(sortingRecords(completedTranscriptInfo) as CompletedSearchTranscriptPayload[]);
        }
    }, [files?.results, filteredRecords, isFiltered, sortingRecords]);

    useEffect(() => {
        if (isArchiveError || isRequestsError || isFilesError) {
            toast.error('Ошибка обновления архива', { toastId: 'archive-error-id', position: 'bottom-right' });
        }
    }, [isArchiveError, isFilesError, isRequestsError]);

    let displayedMonth = 0;
    let displayedYear = 0;

    return (
        <div className='records_list'>
            <RotatingLines
                strokeColor='#814feb'
                strokeWidth='5'
                animationDuration='0.75'
                width='40'
                visible={isLoading && !(isArchiveError || isRequestsError || isFilesError)}
            />
            {(isArchiveError || isRequestsError || isFilesError) &&
                !(isArchiveSuccess && isRequestsSuccess && isFilesSuccess) && <p>Не удалось загрузить архив</p>}
            {isArchiveSuccess && isRequestsSuccess && isFilesSuccess && (
                <>
                    {isFiltered && !isLoading && (
                        <>
                            <div className='records_count'>Найдено {filteredRecords.length} записей</div>
                            {sortedSearchRecords.map((obj) => {
                                return (
                                    <RecordItem
                                        key={`item_${obj.id}`}
                                        id={obj.session_id}
                                        fileId={obj.file_id}
                                        timestamp={obj.timestamp}
                                        name={obj.name}
                                        streamUrl={obj.stream_url}
                                        duration={obj.duration}
                                        searchedText={obj.highlight}
                                    />
                                );
                            })}
                        </>
                    )}

                    {!isFiltered &&
                        requests.map((obj) => {
                            const file = files?.results.find((file) => file.file_id === obj.file_id);
                            return (
                                <RequestItem
                                    key={`request_${obj.id}`}
                                    id={obj.session_id}
                                    taskId={obj.task_id}
                                    name={file?.path[file?.path.length - 1] || ''}
                                    timestamp={obj.timestamp}
                                    duration={file?.duration || 0}
                                    isFailed={obj.task_status === 'failed' || obj.task_status === 'timeout'}
                                />
                            );
                        })}
                    {!isFiltered &&
                        sortedRecords.map((obj) => {
                            let currentMonth = new Date(obj.timestamp).getMonth();
                            let currentYear = new Date(obj.timestamp).getFullYear();
                            let checkNewDate = currentMonth !== displayedMonth || currentYear !== displayedYear;

                            if (checkNewDate) {
                                displayedMonth = currentMonth;
                                displayedYear = currentYear;
                            }

                            return checkNewDate ? (
                                <div key={`wrapper_${obj.id}`} style={{ width: '100%' }}>
                                    <p className='limiter_line' key={`line_${obj.id}`}>
                                        {MONTHS[displayedMonth]} {displayedYear}
                                    </p>
                                    <RecordItem
                                        key={`item_${obj.id}`}
                                        id={obj.session_id}
                                        fileId={obj.file_id}
                                        timestamp={obj.timestamp}
                                        name={obj.name}
                                        streamUrl={obj.stream_url}
                                        duration={obj.duration}
                                    />
                                </div>
                            ) : (
                                <RecordItem
                                    key={`item_${obj.id}`}
                                    id={obj.session_id}
                                    fileId={obj.file_id}
                                    timestamp={obj.timestamp}
                                    name={obj.name}
                                    streamUrl={obj.stream_url}
                                    duration={obj.duration}
                                />
                            );
                        })}
                </>
            )}
        </div>
    );
};

export default RecordsList;
