export function msToDefaultTimeFormat(duration: number): string {
    const time = msToDefaultTimeFormatDivided(duration);
    return time.join(':');
}

export function msToDefaultTimeFormatDivided(duration: number): string[] {
    const hours = ~~(duration / (1000 * 60 * 60));
    const minutes = ~~((duration / (1000 * 60)) % 60);
    const seconds = ~~((duration / 1000) % 60);

    const ret: string[] = [];

    ret.push(hours.toString().padStart(2, '0'));
    ret.push(minutes.toString().padStart(2, '0'));
    ret.push(seconds.toString().padStart(2, '0'));

    return ret;
}

export function secToDefaultTimeFormat(duration: number): string {
    const time = secToDefaultTimeFormatDivided(duration);
    return time.join(':');
}

export function secToDefaultTimeFormatDivided(duration: number): string[] {
    const hours = ~~(duration / (60 * 60));
    const minutes = ~~((duration / 60) % 60);
    const seconds = ~~(duration % 60);

    const ret: string[] = [];

    ret.push(hours.toString().padStart(2, '0'));
    ret.push(minutes.toString().padStart(2, '0'));
    ret.push(seconds.toString().padStart(2, '0'));

    return ret;
}

export function toMinTimeFormat(duration: number): string {
    const hours = ~~(duration / 3600);
    const minutes = ~~((duration % 3600) / 60);
    const seconds = ~~duration % 60;

    let ret = '';
    if (hours !== 0) {
        ret += `${hours}:`.padStart(3, '0');
    }

    ret += `${minutes}:`.padStart(3, '0') + `${seconds}`.padStart(2, '0');

    return ret;
}

export function getDateFromTimestamp(timestamp: number | string) {
    const date = new Date(timestamp);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');

    return `${day}.${month}.${date.getFullYear()}`;
}

export function getYMDDateFromTimestamp(timestamp: number | string) {
    const date = new Date(timestamp);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');

    return `${date.getFullYear()}-${month}-${day}`;
}

export function getTimeFromTimestamp(timestamp: number | string, withSec?: boolean) {
    const date = new Date(timestamp);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    if (!withSec) {
        return `${hours}:${minutes}`;
    } else {
        return `${hours}:${minutes}:${seconds}`;
    }
}
