import ArchiveControl from 'components/pages/records/archive/control/ArchiveControl';
import ArchiveHeader from 'components/pages/records/archive/header/ArchiveHeader';
import SectionsLayout from 'components/pages/records/layout/SectionsLayout';
// import PopUpWindow from 'components/views/popUpWindow/PopUpWindow';
import RecordsFoldersContainer from 'components/views/recordsFoldersContainer/RecordsFoldersContainer';
import SearchFilter from 'components/views/searchFilter/SearchFilter';

import './ArchivePage.scss';

const ArchivePage: React.FC = (): JSX.Element => (
    <div className='archive_page'>
        {/* <PopUpWindow /> */}
        <SectionsLayout
            header={<ArchiveHeader />}
            control={<ArchiveControl />}
            firstChildren={<RecordsFoldersContainer />}
            secondChildren={<SearchFilter />}
        />
    </div>
);

export default ArchivePage;
