import { useAppSelector } from 'hooks/useAppSelector';

interface ArchiveHeaderProps {}

const ArchiveHeader: React.FC<ArchiveHeaderProps> = () => {
    const { records, filteredRecords, isFiltered } = useAppSelector((state) => state.archive);

    return (
        <p className='page_title'>
            Мои записи
            <span className='records_number'>{isFiltered ? filteredRecords.length : records.length}</span>
        </p>
    );
};

export default ArchiveHeader;
