import { WEEKDAY } from 'helpers/Constants';

const WeekdaysBar: React.FC = (): JSX.Element => {
    let weeks: JSX.Element[] = [];

    WEEKDAY.forEach((day) => {
        weeks.push(
            <div key={day} className={`calendar_cell ${day === WEEKDAY[5] || day === WEEKDAY[6] ? 'disabled' : ''}`}>
                {day}
            </div>,
        );
    });

    return <div className='weekdays_bar'>{weeks}</div>;
};

export default WeekdaysBar;
