import { Outlet, NavLink } from 'react-router-dom';

import avatar from 'assets/avatar-mock.png';
import { useAppSelector } from 'hooks/useAppSelector';
import './ProfilePage.scss';

const ProfilePage: React.FC = () => {
    const { firstName, lastName } = useAppSelector((state) => state.user);

    return (
        <div className='profile_page_container'>
            <div className='navigation_section'>
                <img src={avatar} alt='user_photo' className='avatar' />
                <div className='username'>
                    {firstName} {lastName}
                </div>
                <div className='navigation_links'>
                    <NavLink to='info' className={({ isActive }) => (isActive ? 'link link--active' : 'link')}>
                        Основная информация
                    </NavLink>
                    {/* <NavLink to='settings' className={({ isActive }) => (isActive ? 'link link--active' : 'link')}>
                        Настройки
                    </NavLink> */}
                    {/* TODO: TAS-1007 */}
                    {/* <NavLink to='receipts' className={({ isActive }) => (isActive ? 'link link--active' : 'link')}>
                        Мои квитанции
                    </NavLink> */}
                </div>
            </div>
            <div className='actions_section'>
                <Outlet />
            </div>
        </div>
    );
};

export default ProfilePage;
