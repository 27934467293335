export interface FileInfoPayload {
    name: string;
    id: string;
    created?: boolean;
}

export interface FileErrorPayload {
    name: string;
    error: string;
}

export const FILE_ERROR = {
    TYPE: 'type',
    UPLOADING: 'uploading',
};

export interface RecordFileUploaderProps {
    navigateToNextStep: () => void;
    uploadedFiles: FileInfoPayload[];
    addFileToUploaded: (file: FileInfoPayload) => void;
    handleCancelUpload: () => void;
    removeUploadedFileById: (id: string) => void;
}

export interface TranscriptInfoFormProps {
    navigateToNextStep: () => void;
    handleCancelUpload: () => void;
    clearAllUploadedFiles: () => void;
    uploadedFiles: FileInfoPayload[];
}

export interface UploadingProgressProps {
    navigateToFirstStep: () => void;
}
