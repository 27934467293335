import { Link, matchPath, useLocation } from 'react-router-dom';

import RouterTabs from './routerTabs/RouterTabs';
import UserDropdown from './userDropdown/UserDropdown';

// TODO: TAS-1007
// import { useGetUserBalanceInfoQuery } from 'api/routes/AppApi';

import logo from 'assets/archive/logo.svg';
// import add from 'assets/balance/add.svg';
// import wallet from 'assets/balance/wallet.svg';

import { useAppSelector } from 'hooks/useAppSelector';

import './RecordsHeader.scss';

const RecordsHeader: React.FC = () => {
    const { pathname } = useLocation();
    const isArchivePath = matchPath('/archive/', pathname);

    const { isFiltered } = useAppSelector((state) => state.archive);
    // const { availableBalance } = useAppSelector((state) => state.user);

    // TODO: TAS-1007
    // useGetUserBalanceInfoQuery(null, {
    //     pollingInterval: isArchivePath && !isFiltered ? undefined : 60000,
    // });

    return (
        <header className='records__header'>
            <div>
                <Link to='uploading' className='logo_link'>
                    <img src={logo} className='logo_image' alt='logo' />
                </Link>
            </div>
            <RouterTabs />
            {/* Скрыто на время показа */}
            {/* <div className='balance'>
                <img src={wallet} className='balance__icon' alt='wallet' />
                <p className='balance__value'>{availableBalance}</p>
                <Link to='/balance/receipt'>
                    <img src={add} className='balance__icon' alt='add' />
                </Link>
            </div> */}
            <div>
                <UserDropdown />
            </div>
        </header>
    );
};

export default RecordsHeader;
