import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ReactComponent as Arrow } from 'assets/arrow-with-body.svg';
import { sortingList, sortOrder } from 'helpers/Constants';
import useOutsideClick from 'hooks/useOutsideClick';

import './Sorting.scss';

interface SortingProps {}

const Sorting: React.FC<SortingProps> = () => {
    const dropdownRef = useRef<HTMLDivElement>(null);

    const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
    const [sortOrderIsDesc, setSortOrderIsDesc] = useState(true);
    const [sortPropertyInd, setSortPropertyInd] = useState<number>(0);

    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        searchParams.set('sort', sortingList[sortPropertyInd].sortProperty);
        searchParams.set('order', sortOrderIsDesc ? sortOrder.desc : sortOrder.asc);
        setSearchParams(searchParams);
    }, [searchParams, setSearchParams, sortOrderIsDesc, sortPropertyInd]);

    useOutsideClick([dropdownRef], () => {
        setDropdownIsOpen(false);
    });

    const handleClick = (index: number) => {
        if (sortPropertyInd === index) {
            setSortOrderIsDesc(!sortOrderIsDesc);
        } else {
            if (!sortOrderIsDesc) {
                setSortOrderIsDesc(true);
            }
            setSortPropertyInd(index);
        }
    };

    return (
        <div className='sorting_wrapper' onClick={() => setDropdownIsOpen(!dropdownIsOpen)} ref={dropdownRef}>
            <span className='sorting_tip'>Сортировать по: </span>
            <div className='sorting_property'>
                {sortingList[sortPropertyInd].label}
                <ul className={classNames('dropdown_menu', { 'dropdown_menu--open': dropdownIsOpen })}>
                    {sortingList.map((el, i) => (
                        <li className='dropdown_menu__item' key={el.sortProperty} onClick={() => handleClick(i)}>
                            {el.label}
                        </li>
                    ))}
                </ul>
                <span className='sorting_order'>
                    <Arrow className={`arrow ${sortOrderIsDesc ? 'arrow--down' : 'arrow--up'}`} />
                </span>
            </div>
        </div>
    );
};

export default Sorting;
