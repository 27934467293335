import { getDate, getMonth, getYear } from 'date-fns';
import React, { useCallback, useMemo } from 'react';

import { ReactComponent as CalendarIcon } from 'assets/calendar.svg';
import { ReactComponent as CrossIcon } from 'assets/cross-bold.svg';
import { HeaderProps } from 'components/views/searchFilter/calendar/Calendar.interfaces';
import { calendarModes, MONTHS_ABBREVIATIONS } from 'helpers/Constants';

import './CalendarHeader.scss';

const CalendarHeader: React.FC<HeaderProps> = (props): JSX.Element => {
    const getDateAbbr = useCallback((date: Date): string => {
        return `${getDate(date)} ${MONTHS_ABBREVIATIONS[getMonth(date)]} ${getYear(date)}`;
    }, []);

    const handleResetSelectedDate = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        props.resetSelectedDate();
    };

    const dateLabel = useMemo(() => {
        if (props.currentMode === calendarModes.date) {
            return getDateAbbr(props.searchedDate || props.selectedDate);
        } else {
            if (!props.rangeSelector.focus) {
                return '';
            }
            if (props.rangeSelector.waiting === true) {
                return `${getDateAbbr(props.rangeSelector.startOfOPeriod)} - `;
            } else {
                return `${getDateAbbr(props.rangeSelector.startOfOPeriod)} - ${getDateAbbr(
                    props.rangeSelector.endOfOPeriod,
                )}`;
            }
        }
    }, [
        getDateAbbr,
        props.currentMode,
        props.rangeSelector.endOfOPeriod,
        props.rangeSelector.focus,
        props.rangeSelector.startOfOPeriod,
        props.rangeSelector.waiting,
        props.searchedDate,
        props.selectedDate,
    ]);

    return (
        <div className='calendar_header' onClick={() => props.changeView(!props.view)}>
            <CalendarIcon />
            <div className='current_date'>{dateLabel}</div>
            {(props.rangeSelector.focus || props.searchedDate) && (
                <div className='reset' onClick={(e) => handleResetSelectedDate(e)}>
                    <span>Сбросить</span>
                    <CrossIcon />
                </div>
            )}
        </div>
    );
};

export default CalendarHeader;
