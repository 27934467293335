import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import {
    DemoModel,
    DemoPayload,
    DemoProjectsInfoModel,
    DemoProjectsInfoPayload,
    DemoProjectsPayload,
} from 'api/Models';
import { getAPIToken } from 'helpers/LocalStorageActions';

export const demoApi = createApi({
    reducerPath: 'demoApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `https://muryshev-protocol-api-mirror.hf.space`,
        prepareHeaders: (headers) => {
            //не надо на хф слать bearer ключ, он от этого страдает предположительно
            // const token = getAPIToken();
            // if (!headers.has('authorization') && token) {
            //     headers.set('authorization', `Bearer ${token}`);
            // }
            return headers;
        },
    }),

    endpoints: (build) => ({
        getBrief: build.mutation<DemoPayload, DemoModel>({
            query: (text) => ({
                url: `getsummary`,
                method: 'POST',
                body: text,
            }),
        }),
        getTopics: build.mutation<DemoPayload, DemoModel>({
            query: (text) => ({
                url: `getfollowup`,
                method: 'POST',
                body: text,
            }),
        }),
        getTasks: build.mutation<DemoPayload, DemoModel>({
            query: (text) => ({
                url: `getagenda`,
                method: 'POST',
                body: text,
            }),
        }),
        getHighlights: build.mutation<DemoPayload, DemoModel>({
            query: (text) => ({
                url: `gethighlights`,
                method: 'POST',
                body: text,
            }),
        }),
        getShortInfo: build.mutation<DemoPayload, DemoModel>({
            query: (text) => ({
                url: `getprojectinfo`,
                method: 'POST',
                body: text,
            }),
        }),
        getProjects: build.mutation<DemoProjectsPayload, DemoModel>({
            query: (text) => ({
                url: `getprojectlist`,
                method: 'POST',
                body: text,
            }),
        }),
        getProjectsInfo: build.mutation<DemoProjectsInfoPayload, DemoProjectsInfoModel>({
            query: (text) => ({
                url: `getprojectdetails`,
                method: 'POST',
                body: text,
            }),
        }),
        downloadListOfTasks: build.query<any, {}>({
            // Изменить после появления запроса на скачивание списка задач
            query: () => ({
                url: ``,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getAPIToken()}`,
                },
                responseHandler: async (response) => {
                    if (response.status === 200) {
                        let blob = await response.blob();
                        const downloadUrl = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = downloadUrl;
                        link.download = 'Tasks.docs';
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                    }
                },
            }),
        }),
    }),
});

export const {
    useGetBriefMutation,
    useGetTopicsMutation,
    useGetTasksMutation,
    useGetHighlightsMutation,
    useGetShortInfoMutation,
    useGetProjectsMutation,
    useGetProjectsInfoMutation,
    useLazyDownloadListOfTasksQuery,
} = demoApi;
