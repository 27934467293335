import { ReactComponent as MailIcon } from 'assets/mail-with-arrow.svg';

const CheckEmailText: React.FC = (): JSX.Element => {
    return (
        <div className='confirmation_text'>
            <MailIcon className='icon' />
            <p>
                Вам на почту отправлено письмо. <br /> Пожалуйста, перейдите по ссылке для восстановления пароля
            </p>
        </div>
    );
};

export default CheckEmailText;
